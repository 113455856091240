import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApiOperation } from '../../hooks/useApiOperation';
import { AuthApi } from '../../lib/auth.api';
import { technicianActions, userActions } from '../../utils/constants';
import Skeleton from '../../utils/shared/Skeleton';

const DashboardActions = () => {
  const { startApiOperation, terminateApiOperation, apiLoading } = useApiOperation()
  const [userData, setUsersData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        startApiOperation()
        const data = await AuthApi.getUser();
        setUsersData(data);
        terminateApiOperation()
      } catch (error) {
        console.error('Error fetching data:', error);
        terminateApiOperation()
      }
    };
    fetchData();
  }, []);

  const actions = userData?.user?.role === "user" ? userActions : technicianActions;
  const navigate = useNavigate();

  const Action = () => {
    if (!actions.length) return null;
    return (
      <>
        {actions.map((action) => (
          <div
            key={action.name}
            className="border rounded-lg shadow-sm cursor-pointer hover:scale-105 transition-all hover:shadow-md group bg-white"
            onClick={() => navigate(action.href)}
          >
            <div className="m-3">
              <div className="flex justify-between items-center">
                <span className="w-10">
                  <action.icon
                    className={`${action.iconForeground} ${action.iconBackground} p-2 rounded-md`}
                  />
                </span>
                <span
                  className="text-gray-300 group-hover:text-gray-400 transition-all"
                  aria-hidden="true"
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                  </svg>
                </span>
              </div>
              <div className="pt-3">
                <h3 className="font-medium text-xl">{action.name}</h3>
                <p className="mt-1 text-gray-500 text-sm">
                  {action.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </>
    )
  }
  if (apiLoading) return <div className="grid md:grid-cols-3 gap-3 sm:grid-cols-2">{Array.from({ length: 3 }).map((_, index) => <Skeleton key={index} />)}</div>
  return (
    <div className="grid md:grid-cols-3 gap-3 sm:grid-cols-2">
      <Action />
    </div>
  )
}

export { DashboardActions };