import React from 'react';



const CustomInput = ({ options }) => {
  const {
    heading_icon,
    heading_label,
    name,
    id,
    value,
    onChange,
    onBlur,
    autoComplete,
    placeholder,
    type = 'text',
    disabled = false,
    required = false,
  } = options;

  const RenderLabel = () => {

    if (!heading_label) return null
    return <label htmlFor={id} className="mb-1 flex items-center justify-start text-gray-600 text-sm font-bold">
      {heading_label}
      {heading_icon && <span className="ml-2">{heading_icon}</span>}
    </label>
  }

  return (
    <div className="w-full">
      <RenderLabel />
      <input
        id={id}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete={autoComplete}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        className="w-full rounded-md border border-gray-300 p-2 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-200"
      />
    </div>
  );
};

export default CustomInput;