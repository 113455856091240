import { CheckCircleIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function PaymentSuccess() {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(3); // Start countdown from 3

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prev) => prev - 1);
        }, 1000);

        // Redirect when countdown reaches 0
        const redirectTimer = setTimeout(() => {
            navigate(`/settings`, { state: { activeTab: 'HARDWARE', openModal: false } });
        }, 3000);

        return () => {
            clearInterval(timer);
            clearTimeout(redirectTimer);
        };
    }, [navigate]);

    return (
        <div className='w-full h-screen flex flex-col justify-center items-center'>
            <CheckCircleIcon className='size-20 text-green-500' />
            <h1 className="text-2xl font-bold">Thanks for Purchasing the Hardware</h1>
            <p className="text-gray-500 mt-2">
                Redirecting you to settings in <span className="font-semibold">{countdown}</span>...
            </p>
        </div>
    );
}

export default PaymentSuccess;
