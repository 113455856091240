
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import PageTitleHeader from './PageTitleHeader';
import { StripeApi } from '../../lib/stripe.api';
import { useApiOperation } from '../../hooks/useApiOperation';
import { NOTIFY_TYPE } from '../../utils/constants';

const ELEMENT_OPTIONS = {
    style: {
        base: {
            fontWeight: '500',
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            color: '#424770',
            letterSpacing: '0.025em',
        },
        invalid: {
            color: '#DC2638',
        },
    },
};

const cardElementStyles =
    'w-full border-disabled-dark border-[1px] rounded-[8px] focus:border-primary focus:ring-0 text-[14px] p-3';

const StripeCardInfo = forwardRef(({ companyAddressInfo, setCompanyAddressInfo, cardSubmitStartApiOperation, cardSubmitTerminateApiOperation }, ref) => {
    const { startApiOperation, terminateApiOperation } = useApiOperation();


    const elements = useElements();
    const stripe = useStripe();
    const [errors, setErrors] = useState({ cardNumber: '', cvc: '', expiry: '' });
    const [placeHolders, setPlaceHolders] = useState({
        cardNumber: '1234 1234 1234 1234',
        expiry: 'MM / YY',
        cvc: 'CVC',
    });

    const handleCardNumberChange = (event) => {
        setErrors((prev) => ({ ...prev, cardNumber: event.error?.message || '' }));
    };
    const handleCardCvcChange = (event) => {
        setErrors((prev) => ({ ...prev, cvc: event.error?.message || '' }));
    };
    const handleCardExpiryChange = (event) => {
        setErrors((prev) => ({ ...prev, expiry: event.error?.message || '' }));
    };

    const fetchCardDetails = async () => {
        try {
            startApiOperation();
            const companyPaymentDetails = await StripeApi.getCustomerCardInfo();

            if (!companyPaymentDetails || !companyPaymentDetails.card) {
                console.warn('No card details found for the customer.');
                terminateApiOperation();
                return;
            }

            const { card, billing_details } = companyPaymentDetails;
            const { last4, exp_month, exp_year } = card;
            const { city, line1, line2, postal_code, state } = billing_details.address || {};

            setPlaceHolders({
                cardNumber: `**** **** **** ${last4}`,
                expiry: `${exp_month} / ${exp_year}`,
                cvc: '****',
            });

            setCompanyAddressInfo({
                streetLine1: line1 || '',
                streetLine2: line2 || '',
                city: city || '',
                state: state || '',
                zip: postal_code || '',
            });

            // Dispatch event for existing card detection
            window.dispatchEvent(new CustomEvent('existingCardLoaded', {
                detail: { hasExistingCard: true },
            }));

            terminateApiOperation();
        } catch (error) {
            console.error('Error fetching card details:', error);
            terminateApiOperation(["Failed to load card details. Please try again later."]);
        }
    };

    const handleSubmit = async () => {

        try {
            cardSubmitStartApiOperation()
            if (!stripe || !elements) return;
            const cardNumberElement = elements.getElement(CardNumberElement);
            const cardCvcElement = elements.getElement(CardCvcElement);
            const cardExpiryElement = elements.getElement(CardExpiryElement);

            if (!cardNumberElement) {

                cardSubmitTerminateApiOperation(["Card number element not found."]);
                return
            }

            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardNumberElement,
                billing_details: {
                    address: {
                        postal_code: companyAddressInfo.zip,
                        state: companyAddressInfo.state,
                        city: companyAddressInfo.city,
                        line1: companyAddressInfo.streetLine1,
                        line2: companyAddressInfo.streetLine2
                    },
                },
            });

            if (error) {
                cardSubmitTerminateApiOperation([`Stripe Error: ${error.message}`]);
                return;
            }

            await StripeApi.updateCustomerCard({ paymentMethodId: paymentMethod.id });

            cardNumberElement.clear();
            cardCvcElement?.clear();
            cardExpiryElement?.clear();

            await fetchCardDetails();
            cardSubmitTerminateApiOperation(['Payment method updated successfully'], NOTIFY_TYPE.Success)

        } catch (apiError) {
            cardSubmitTerminateApiOperation(["Something went wrong while updating the card. Please try again later."]);
        }
    };


    useEffect(() => {
        fetchCardDetails();
    }, []);

    useEffect(() => {
        if (!elements) return;

        const cardNumberElement = elements.getElement(CardNumberElement);
        const cardCvcElement = elements.getElement(CardCvcElement);
        const cardExpiryElement = elements.getElement(CardExpiryElement);

        if (cardNumberElement) cardNumberElement.on('change', handleCardNumberChange);
        if (cardCvcElement) cardCvcElement.on('change', handleCardCvcChange);
        if (cardExpiryElement) cardExpiryElement.on('change', handleCardExpiryChange);

        return () => {
            if (cardNumberElement) cardNumberElement.off('change', handleCardNumberChange);
            if (cardCvcElement) cardCvcElement.off('change', handleCardCvcChange);
            if (cardExpiryElement) cardExpiryElement.off('change', handleCardExpiryChange);
        };
    }, [elements]);



    useImperativeHandle(ref, () => ({
        handleSubmit,
    }));
    const ErrorMessage = ({ error }) => {
        if (!error) return null;
        return <div className="text-red-500 text-sm mt-2">{error}</div>;
    };

    return (
        <div className="flex w-full flex-col gap-4 rounded-lg p-6">
            <PageTitleHeader title="Payment Information" subHeading="Card Details" />

            <div className="w-full space-y-5 md:grid md:grid-cols-2 md:gap-5 md:space-y-0">
                {/* Card Number */}
                <div className="col-span-2">
                    <CardNumberElement
                        className={cardElementStyles}
                        id="cardNumber"
                        options={{
                            ...ELEMENT_OPTIONS,
                            placeholder: placeHolders.cardNumber || 'Card number',
                        }}
                    />
                    <ErrorMessage error={errors.cardNumber} />
                </div>

                {/* CVC */}
                <div className="col-span-1">
                    <CardCvcElement
                        className={cardElementStyles}
                        id="cvc"
                        options={{
                            ...ELEMENT_OPTIONS,
                            placeholder: placeHolders.cvc || 'CVV',
                        }}
                    />
                    <ErrorMessage error={errors.cvc} />
                </div>

                {/* Expiry Date */}
                <div className="col-span-1">
                    <CardExpiryElement
                        className={cardElementStyles}
                        id="expiry"
                        options={{
                            ...ELEMENT_OPTIONS,
                            placeholder: placeHolders.expiry || 'Expiry date',
                        }}
                    />
                    <ErrorMessage error={errors.expiry} />
                </div>

            </div>
        </div>
    );
});

export { StripeCardInfo };
