import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Chemicals from './chemical/chemicals';
import Technicians from './Technicians/Technicians';
import { Tabs as SettingsTabs } from '../components/Tabs/Tabs';
import Hardware from './hardware/hardware';

export default function Settings({ children }) {
    const location = useLocation();
    const sectionTabs = [
        {
            title: 'Chemicals',
            identifier: 'CHEMICALS',
        },
        {
            title: 'Hardware',
            identifier: 'HARDWARE',
        },
        {
            title: 'Technicians',
            identifier: 'TECHNICIANS',
        },
    ];

    // Initialize the current tab based on the navigation state
    const [currentTab, setCurrentTab] = useState(sectionTabs[0]);

    useEffect(() => {
        // Check if the navigation state contains an activeTab
        if (location.state?.activeTab) {
            const activeTab = sectionTabs.find(tab => tab.identifier === location.state.activeTab);
            if (activeTab) {
                setCurrentTab(activeTab);
            }
        }
    }, [location.state]);

    const handleTabsClick = (identifier) => {
        if (identifier === 'CHEMICALS') {
            setCurrentTab(sectionTabs[0]);
        } else if (identifier === 'HARDWARE') {
            setCurrentTab(sectionTabs[1]);
        } else {
            setCurrentTab(sectionTabs[2]);
        }
    };

    const settingTabOptions = {
        active: currentTab.identifier,
        fullWidth: true,
        tabs: [
            { name: 'Chemicals', identifier: 'CHEMICALS' },
            { name: 'Hardware', identifier: 'HARDWARE' },
            { name: 'Technicians', identifier: 'TECHNICIANS' },
        ],
        handleClick: handleTabsClick,
    };

    const ActiveTab = () => {
    let Component;

    switch (currentTab.identifier) {
        case 'CHEMICALS':
            Component = Chemicals;
            break;
        case 'HARDWARE':
            Component = Hardware;
            break;
        default:
            Component = Technicians;
    }

    return (
        <div className="flex flex-col w-full xl:w-4/5 px-5">
            <Component />
        </div>
    );
};
    return (
        <div>
            <div className="p-5 xl:w-4/5">
                <h1 className="text-2xl font-bold p-3">Settings</h1>
                {children}
                <SettingsTabs {...settingTabOptions} />
            </div>
            <ActiveTab/>
        </div>
    );
}