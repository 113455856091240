import { HTTP_METHODS } from "../utils/constants";
import { sendRequest2 } from "../utils/request-service";

const API_REQUESTS = {
    VERIFY_EMAIL: {
        path: '/admin/activate-hardware',
        method: HTTP_METHODS.GET,
    },

};

export const AddHardware = {

    verifyEmail: (hwid,userId) => {
        return sendRequest2(
            API_REQUESTS.VERIFY_EMAIL.method,
            `${API_REQUESTS.VERIFY_EMAIL.path}/${hwid}/${userId}`
        );
    }

}