import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Loader } from '../../components/Loader/Loader';
import { useApiOperation } from '../../hooks/useApiOperation';
import { AddHardware } from '../../lib/addHardware.api';

const AcceptHardwareInvitation = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation()
    const { startApiOperation, terminateApiOperation, apiLoading } = useApiOperation()
    const { hwid, userId } = useParams();
    const [error, setError] = useState('');


    const getVerification = async () => {

        try {
            startApiOperation()


            if (!localStorage.token) {
                terminateApiOperation(['You are not logged in. Please login to continue.'])
                return navigate(`/signin`, { state: { returnTo: pathname, } });
                ;
            }
            await AddHardware.verifyEmail(hwid, userId)

            navigate(`/hardware`, { state: { activeTab: 'HARDWARE', openModal: true, hwid, userId } });

            terminateApiOperation()
        } catch (error) {
            setError(error.message)
            terminateApiOperation()
        }
    }

    useEffect(() => {

        if (hwid && userId) {
            getVerification()
        }

    }, [hwid, userId,]);
    const GetVerificationStatus = () => {

        return (
            <p className="mb-0 text-red-500">
                <strong>{error}</strong>
            </p>
        );
    }


    if (apiLoading) { return <Loader /> }
    return (
        <div className="mt-28 flex justify-center">
            <div className="flex flex-col items-center">
                <GetVerificationStatus />
            </div>
        </div>
    );
};

export default AcceptHardwareInvitation;