import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useApiOperation } from "../../hooks/useApiOperation";
import { AuthApi } from "../../lib/auth.api";
import { HardwaresApi } from "../../lib/hardwares.api";
import { StripeApi } from "../../lib/stripe.api";
import { NOTIFY_TYPE } from "../../utils/constants";
import { PrimaryButton } from "../Button/PrimaryButton";
import { PopupBox } from "../Popups/PopupBox";
import { AddEditForm } from "./addEditForm";



const AddHardware = ({ refetch, placeHolders, isOpen, setOpen, HwId }) => {

  const initialHardwareState = {
    name: "",
    description: "",
    hwid: HwId,
    errors: [],
    dropdown: "No",
  };

  const stripe = useStripe();
  const elements = useElements();
  const [hardware, setHardware] = useState(initialHardwareState);
  const [openModal, setOpenModal] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [newCard, setNewCard] = useState({
    number: false,
    expiry: false,
    cvc: false,
  });
  const { startApiOperation, terminateApiOperation, apiLoading } =
    useApiOperation();
  const { description, name } = hardware;

  const fetchUser = async () => {
    const user = await AuthApi.getUser();
    const { stripeCustomerId } = user.user;

    if (stripeCustomerId) {
      setCustomerId(stripeCustomerId);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);
  useEffect(() => {
    if (isOpen) {
      setOpenModal(true);
    }
  }, [isOpen]);
  useEffect(() => {
    if (!openModal) {
      setNewCard({
        number: false,
        expiry: false,
        cvc: false,
      });
    }
  }, [openModal]);

  let totalAmount;
  const validateInputs = () => {
    const errors = [];
    if (!name) errors.push("Hardware name is required");
    if (!description) errors.push("Hardware description is required");

    return errors;
  };

  if (elements) {
    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    cardNumberElement?.on("change", (data) => {
      setNewCard((prev) => ({ ...prev, number: data.complete }));
    });
    cardExpiryElement?.on("change", (data) => {
      setNewCard((prev) => ({ ...prev, expiry: data.complete }));
    });
    cardCvcElement?.on("change", (data) => {
      setNewCard((prev) => ({ ...prev, cvc: data.complete }));
    });
  }
  const handleSubmit = async () => {
    let deletedHardwareId = null;
    try {
      startApiOperation();
      const errors = validateInputs();
      if (errors.length) return terminateApiOperation(errors);

      if (!stripe || !elements) {
        return terminateApiOperation(["Stripe is not properly initialized."]);
      }

      const cardElements = getCardElements();
      if (!cardElements) {
        return terminateApiOperation([
          "Please provide your complete card information.",
        ]);
      }
      if (isEditing && (!newCard.number || !newCard.expiry || !newCard.cvc)) {
        return terminateApiOperation([
          "Please complete all card details before submitting.",
        ]);
      }
      const createdHardware = await HardwaresApi.addHardware(hardware);
      deletedHardwareId = createdHardware._id;
      if (customerId) {
        await processExistingCustomer(cardElements, createdHardware._id);
      } else {
        await processNewCustomer(cardElements, createdHardware._id);
      }
      setOpenModal(false);
      setOpen(false);
      setHardware(initialHardwareState);
      setIsEditing(false);
      refetch();
      terminateApiOperation(
        [
          `You have been charged ${totalAmount}$ for creating new hardware and subscription`,
        ],
        NOTIFY_TYPE.Success
      );
    } catch (error) {
      console.error(error);
      terminateApiOperation([
        error.message || error || "An unexpected error occurred.",
      ]);
      if (deletedHardwareId) {
        const deleted = await HardwaresApi.deleteHardware(deletedHardwareId);
      }
    }
  };

  const getCardElements = () => {
    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
      return null;
    }
    return { cardNumberElement, cardExpiryElement, cardCvcElement };
  };

  const processExistingCustomer = async (cardElements, hardwareId) => {
    let paymentMethodId;

    if (newCard.number && newCard.expiry && newCard.cvc) {
      if (Object.values(newCard).some((value) => !value)) {
        return terminateApiOperation([
          "Please provide your complete card information.",
        ]);
      }
      const paymentMethod = await createPaymentMethod(
        cardElements.cardNumberElement
      );
      paymentMethodId = paymentMethod.id;
    }
    const payment = await createAndCapturePayment(
      customerId,
      hardwareId,
      paymentMethodId
    );
  };

  const processNewCustomer = async (cardElements, hardwareId) => {
    const paymentMethod = await createPaymentMethod(
      cardElements.cardNumberElement
    );
    const customer = await StripeApi.getOrCreateCustomer({
      paymentMethodId: paymentMethod.id,
    });

    if (customer.error) {
      console.error("Customer Creation Error:", customer.error);
      return terminateApiOperation(["Failed to create customer."]);
    }

    setCustomerId(customer.id);

    const payment = await createAndCapturePayment(
      customer.id,
      hardwareId,
      paymentMethod.id
    );
  };

  const createAndCapturePayment = async (
    customerId,
    hardwareId,
    paymentMethodId
  ) => {
    const payment = await StripeApi.createPaymentIntent({
      customerId,
      hardwareId,
      paymentMethodId,
    });
    if (!payment.success || payment.error) {
      console.error("Charge Error:", payment.error);
      terminateApiOperation(["Payment failed."]);
      return null;
    }
    totalAmount =
      payment.paymentIntents.hardwarePrice +
      payment.paymentIntents?.subscriptionPrice;
    const paymentIntent = await capturePayment(
      payment.paymentIntents.clientSecret
    );

    if (payment.paymentIntents?.subscriptionClientSecret) {
      await capturePayment(payment.paymentIntents.subscriptionClientSecret);
    }

    return paymentIntent;
  };

  const capturePayment = async (clientSecret) => {
    const { paymentIntent, error } = await stripe.confirmCardPayment(
      clientSecret
    );
    if (error) {
      terminateApiOperation([error.message]);
      throw new Error(error.message);
    }
    return paymentIntent;
  };

  const createPaymentMethod = async (cardNumberElement) => {
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
    });

    if (error) {
      console.error("Payment Method Error:", error);
      terminateApiOperation(["Failed to retrieve payment method."]);
      throw new Error(error.message);
    }

    return paymentMethod;
  };

  return (
    <>
      <div className="flex justify-end mr-4 mt-2">
        <PrimaryButton
        classNames="text-white"
          label="Add hardware"
          onClickEvent={() => {
            setOpenModal(!openModal);
            setOpen(true);
          }}
          displayPlus
        />
      </div>
      <PopupBox
        modal={openModal}
        setModal={setOpenModal}
        handleClose={() => {
          setOpenModal(false);
          setOpen(false);
          setHardware(initialHardwareState);
          setIsEditing(false);
        }}
        title="Add new hardware"
      >
        <AddEditForm
          onSubmit={handleSubmit}
          hardware={hardware}
          setHardware={setHardware}
          actionType="add"
          cardPlaceHolders={placeHolders}
          loading={apiLoading}
          setIsEditing={setIsEditing}
          isEditing={isEditing}
          HwId={HwId}
        />
      </PopupBox>
    </>
  );
};

export default AddHardware;
