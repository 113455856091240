
import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Input } from '../Input/Input';
import CountryFilter from '../CountryFilter/CountryFilter';
import { UserIcon } from '@heroicons/react/24/outline';
import { AccountDetails } from "../../lib/updateAccounts.api";
import { useApiOperation } from "../../hooks/useApiOperation";
import { Loader } from '../Loader/Loader';

const EditProfile = () => {

    const initialState = {
        companyName: "",
        companyPhone: "",
        companyAddress: "",
        companyWebsite: "",
        companyEmail: "",
        country: "United States",
        imageUrl: ""
    }
    const [canUpdate, setCanUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [formData, setFormData] = useState(initialState);
    const [accountExists, setAccountExists] = useState(false);
    const { startApiOperation, terminateApiOperation, apiLoading } = useApiOperation();
    const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

    useEffect(() => {
        fetchAccountDetails();
    }, []);

    const fetchAccountDetails = async () => {
        try {
            startApiOperation();
            const { data } = await AccountDetails.getAccountDetails();
            if (data) {
                setFormData({
                    companyName: data.companyName || "",
                    companyPhone: data.phoneNumber || "",
                    companyAddress: data.address || "",
                    companyWebsite: data.website || "",
                    companyEmail: data.companyEmail || "",
                    country: data.selectCountry || "United States",
                    imageUrl: data.imageUrl || ""
                });
                setAccountExists(true);
            } else {
                setAccountExists(false);
            }
            terminateApiOperation();
        } catch (error) {
            setAccountExists(false);
            terminateApiOperation();
        }
    };

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setImageFile(file);
            setFormData((prev) => ({ ...prev, imageUrl: URL.createObjectURL(file) }));
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        disabled: !canUpdate,
    });

    const handleCancel = () => {
        setCanUpdate(false);
        fetchAccountDetails();
        setImageFile(null);
    };

    const handleSave = async () => {
        try {
            setLoading(true);
            const submitData = new FormData();
            Object.keys(formData).forEach((key) => {
                submitData.append(key, formData[key]);
            });
            if (imageFile) submitData.append("image", imageFile);

            if (accountExists) {
                await AccountDetails.updateAccountDetails(submitData);
            } else {
                await AccountDetails.addAccountDetails(submitData);
            }

            setLoading(false);
            setCanUpdate(false);
            fetchAccountDetails();
        } catch (error) {
            setLoading(false);
            console.error("Failed to update profile:", error);
        }
    };

    if (apiLoading) return <Loader />
    return (
        <div>
            <div className='flex justify-between items-center'>
                <h1 className='text-base font-bold px-5'>Account</h1>
                <div className='flex justify-between items-center'>
                    {canUpdate && (
                        <button
                            className="text-[#1A1C1C] px-[34px] py-2 mr-4 rounded-lg border border-gray-300 text-sm font-medium leading-5 h-[36px]"
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                    )}
                    <button
                        className="bg-[#548EFC] hover:bg-[#2563EB] text-white px-4 py-2 rounded-lg text-sm font-medium leading-5"
                        onClick={canUpdate ? handleSave : () => setCanUpdate(true)}
                    >
                        {loading ? 'Loading' : canUpdate ? 'Save changes' : 'Update Profile'}
                    </button>
                </div>
            </div>
            <hr className="my-5 bg-gray-100 text-gray-100" />
            <div className="md:flex md:justify-start md:items-start border border-gray-100 rounded-lg p-5 gap-[88px] w-full">
                <div className="mb-20 md:mb-0">
                    {formData.imageUrl ? (
                        <img
                            src={formData.imageUrl.startsWith("blob:") ? formData.imageUrl : `${IMAGE_URL}${formData.imageUrl}`}
                            alt="Profile"
                            className="w-[280px] lg:w-[450px] h-[250px] rounded-[5px] border-dotted p-4 bg-blue-50 border-[2px] border-[#548EFC] object-cover"
                        />
                    ) : (
                        <div className="w-[150px] h-[150px] bg-[#548ffc18] rounded-full border-[3.5px] border-[#548EFC] flex items-center justify-center">
                            <UserIcon className='w-16 h-16 text-[#548EFC]' />
                        </div>
                    )}

                    {canUpdate && (
                        <div {...getRootProps()} className="cursor-pointer mt-4">
                            <header className="w-28 mx-auto border border-gray-200 flex flex-col justify-center items-center py-2 px-4 rounded-lg hover:bg-gray-50">
                                <input {...getInputProps()} />
                                <p className="text-black font-medium text-xs hover:text-[#548EFC]">
                                    Upload Image
                                </p>
                            </header>
                        </div>
                    )}
                </div>
                <div className="md:grid md:grid-cols-2 md:gap-5 space-y-5 md:space-y-0 w-full">
                    {Object.entries({
                        companyName: "Company Name",
                        companyPhone: "Company Phone",
                        companyAddress: "Company Address",
                        companyWebsite: "Company Website",
                        companyEmail: "Business Email",
                    }).map(([key, label]) => (
                        <Input
                            key={key}
                            options={{
                                label,
                                name: key,
                                value: formData[key],
                                disabled: !canUpdate,
                                onChange: (e) => setFormData((prev) => ({ ...prev, [key]: e.target.value }))
                            }}
                        />
                    ))}
                    <CountryFilter
                        heading_label="Select Country"
                        id="tabs"
                        name="tabs"
                        disabled={!canUpdate}
                        canUpdate={canUpdate}
                        country={formData.country}
                        defaultValue={"United States"}
                        countriesList={["United States", "Canada"]}
                        onChange={(e) => setFormData((prev) => ({ ...prev, country: e.target.value }))}
                    />
                </div>
            </div>
        </div>
    );
};

export default EditProfile;
