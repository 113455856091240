import { HTTP_METHODS } from "../utils/constants";
import { sendRequest2 } from "../utils/request-service";

const API_REQUESTS = {
  POST_ACCOUNT_DETAILS: {
    path: '/account/account-details',
    method: HTTP_METHODS.POST,
  },

  GET_ACCOUNT_DETAILS: {
    path: '/account/account-details',
    method: HTTP_METHODS.GET,
  },
  UPDATE_ACCOUNT_DETAILS: {
    path: '/account/account-details',
    method: HTTP_METHODS.PATCH,
  },

};

export const AccountDetails = {

  getAccountDetails: () => {
    return sendRequest2(
      API_REQUESTS.GET_ACCOUNT_DETAILS.method,
      API_REQUESTS.GET_ACCOUNT_DETAILS.path
    );
  },
  addAccountDetails: (data) => {
    return sendRequest2(
      API_REQUESTS.POST_ACCOUNT_DETAILS.method,
      `${API_REQUESTS.POST_ACCOUNT_DETAILS.path}`,
      data
    );
  },
  updateAccountDetails: (updatedData) => {
    return sendRequest2(
      API_REQUESTS.UPDATE_ACCOUNT_DETAILS.method,
      `${API_REQUESTS.UPDATE_ACCOUNT_DETAILS.path}`,
      updatedData
    );
  },
}

