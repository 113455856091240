import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { AuthApi } from '../lib/auth.api';

export const axiosProtected = axios.create();

axiosProtected.interceptors.request.use(
  async (config) => {
    let currentDate = new Date();
    const tokenObject = JSON.parse(localStorage.getItem("token"));

    if (!tokenObject || !tokenObject.accessToken) {
      window.location.href = "/signin"; // Redirect if no token exists
      return Promise.reject("No access token found, redirecting to sign-in.");
    }

    const decodedToken = jwtDecode(tokenObject.accessToken);
    
    // Check if the token is expired
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      try {
        // Attempt to rotate the token
        const data = await AuthApi.rotateToken(tokenObject.refreshToken);
        
        if (!data || !data.tokens?.accessToken) {
          throw new Error("Invalid refresh token"); // If no token is returned, throw an error
        }
        
        // Store the new token and update the request headers
        localStorage.setItem("token", JSON.stringify(data.tokens));
        config.headers["Authorization"] = `Bearer ${data.tokens?.accessToken}`;
      } catch (error) {
        // If refresh fails, clear tokens and redirect to sign-in
        localStorage.removeItem("token");
        window.location.href = "/signin";
        return Promise.reject("Session expired, redirecting to sign-in.");
      }
    } else {
      config.headers["Authorization"] = `Bearer ${tokenObject.accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
