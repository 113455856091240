import React, { useEffect, useState } from "react";
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { useApiOperation } from "../../hooks/useApiOperation";
import { StripeApi } from "../../lib/stripe.api";
import CloseIcon from "../../components/icons/CloseIcon";
import EditIcon from "../../components/icons/EditIcon";

const cardElementOptions = {
    style: {
        base: {
            color: "#000000",
            fontSize: "16px",
            "::placeholder": {
                color: "#B3B3B3",
            },
        },
        invalid: {
            color: "#FF0000",
        },
    },
};

const cardElementStyles = "w-full border-disabled-dark border rounded-md focus:border-primary focus:ring-0 text-sm p-2";

const PaymentDetails = ({ hasDefaultPaymentMethod, isEditing, setIsEditing }) => {
    if (!hasDefaultPaymentMethod) return null;

    return (
        <div className="col-span-1">
            {isEditing ? (
                <div className="p-1 bg-gray-100 rounded-full cursor-pointer" onClick={() => setIsEditing(false)}>
                    <CloseIcon />
                </div>
            ) : (
                <div className="p-1 bg-gray-100 rounded-full cursor-pointer" onClick={() => setIsEditing(true)}>
                    <EditIcon />
                </div>
            )}
        </div>
    );
};

const FetchCardInfo = ({ actionType, setIsEditing, isEditing }) => {
    const { startApiOperation, terminateApiOperation } = useApiOperation();
    const [hasDefaultPaymentMethod, setHasDefaultPaymentMethod] = useState(false);
    const [loading, setLoading] = useState(true);
    const [placeHolders, setPlaceHolders] = useState({
        cardNumber: "1234 1234 1234 1234",
        expiry: "MM / YY",
        cvc: "CVC",
    });

    const fetchCustomerData = async () => {
        try {
            startApiOperation();
            const cardInfo = await StripeApi.getCustomerCardInfo();
            if (!cardInfo) {
                setIsEditing(true);
                return;
            }
            const { last4, exp_month, exp_year } = cardInfo.card;

            setPlaceHolders({
                cardNumber: `**** **** **** ${last4}`,
                expiry: `${exp_month} / ${exp_year}`,
                cvc: "****",
            });
            setHasDefaultPaymentMethod(true);
        } catch (error) {
            console.error("Error fetching customer data:", error);
            setIsEditing(true);
        } finally {
            terminateApiOperation();
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCustomerData();
    }, []);

    if (loading || actionType !== "add") return null;

    return (
        <div className="mt-5">
            <div className="flex justify-between items-center">
                <p className="py-2 text-sm">Payment Details</p>
                <PaymentDetails hasDefaultPaymentMethod={hasDefaultPaymentMethod} isEditing={isEditing} setIsEditing={setIsEditing} />
            </div>
            <div className="w-full space-y-5 md:grid md:grid-cols-5 md:gap-2 md:space-y-0">
                <div className="col-span-3">
                    <CardNumberElement className={cardElementStyles} options={{ ...cardElementOptions, placeholder: placeHolders.cardNumber, disabled: !isEditing }} />
                </div>
                <div className="col-span-1">
                    <CardCvcElement className={cardElementStyles} options={{ ...cardElementOptions, placeholder: placeHolders.cvc, disabled: !isEditing }} />
                </div>
                <div className="col-span-1">
                    <CardExpiryElement className={cardElementStyles} options={{ ...cardElementOptions, placeholder: placeHolders.expiry, disabled: !isEditing }} />
                </div>
            </div>
        </div>
    );
};

export default FetchCardInfo;
