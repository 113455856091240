


import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useRef, useState } from 'react';
import { StripeAddressInfo } from './StripeAddressInfo';
import { StripeCardInfo } from './StripeCardInfo';
import { useApiOperation } from '../../hooks/useApiOperation';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const initialAddressFormState = {
    streetLine1: '',
    streetLine2: '',
    city: '',
    state: 'TX',
    zip: '',
};

const PaymentInfoSection = () => {
    const { startApiOperation, terminateApiOperation, apiLoading: isSubmitting } = useApiOperation(); 

    const [companyAddressInfo, setCompanyAddressInfo] = useState(initialAddressFormState);
    const stripeCardInfoRef = useRef(null); 

    const handleFieldChange = (fieldName, value) => {
        setCompanyAddressInfo({
            ...companyAddressInfo,
            [fieldName]: value,
        });
    };

    const handleSaveChanges = async () => {
        if (stripeCardInfoRef.current) {

            try {
                startApiOperation()
                await stripeCardInfoRef.current.handleSubmit(); 
                terminateApiOperation()
            } catch (error) {
                terminateApiOperation([
                    'Something went wrong while updating payment info. Please try again later',
                ]);
            }
        }
    };

    return (
        <>
            <div className="flex justify-between items-center px-3">
                <h1 className="text-lg font-bold">Payment Info</h1>
                <div className="flex gap-2">
                    <button className="border border-gray-500 text-gray-500 px-4 py-2 rounded-lg">
                        Cancel
                    </button>
                    <button
                        onClick={handleSaveChanges}
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                        disabled={isSubmitting}
                    >
                       {isSubmitting?"updating...":"Save Changes"}
                    </button>
                </div>
            </div>
            <hr className="my-5 bg-gray-100 text-gray-100" />
            <Elements stripe={stripePromise}>
                <StripeCardInfo ref={stripeCardInfoRef} cardSubmitStartApiOperation={startApiOperation} cardSubmitTerminateApiOperation={terminateApiOperation}  companyAddressInfo={companyAddressInfo} setCompanyAddressInfo={setCompanyAddressInfo} />
            </Elements>
            <StripeAddressInfo addressInfo={companyAddressInfo} handleChange={handleFieldChange} />
        </>
    );
};

export { PaymentInfoSection };
