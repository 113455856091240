import { HTTP_METHODS } from "../utils/constants";
import { sendRequest } from "../utils/request-service";

export const API_REQUESTS = {
  GET_HARDWARES: {
    path: "/hardware/getHardwares",
    method: HTTP_METHODS.GET,
  },
  ADD_HARDWARE: {
    path: "/hardware/addHardware",
    method: HTTP_METHODS.POST,
  },
  UPDATE_HARDWARE: {
    path: "/hardware/editHardware",
    method: HTTP_METHODS.PATCH,
  },
  DELETE_HARDWARE: {
    path: "/hardware/deleteHardware",
    method: HTTP_METHODS.DELETE,
  },
  Get_Hardware_By_Hwid: {
    path: "/hardware/getHardwareByUserIdAndHwid",
    method: HTTP_METHODS.GET,
  },
  GET_HARDWARES_FROM_STRIPE: {
    path: "/hardware/getHardwaresFromStripe",
    method: HTTP_METHODS.GET,
  },
  GET_ACTIVE_HARDWARES: {
    path: "/hardware/getActiveHardwares",
    method: HTTP_METHODS.POST,
  },
  GET_UNASSIGNED_HARDWARES:{
    path: "/hardware/getUnassignedHardwares",
    method: HTTP_METHODS.GET,
  }
};

export const HardwaresApi = {
  fetchHardwares: () => {
    return sendRequest(
      API_REQUESTS.GET_HARDWARES.method,
      `${API_REQUESTS.GET_HARDWARES.path}`
    );
  },
  addHardware: (data) => {
    return sendRequest(
      API_REQUESTS.ADD_HARDWARE.method,
      `${API_REQUESTS.ADD_HARDWARE.path}`,
      data
    );
  },
  updateHardware: (hardware) => {
    return sendRequest(
      API_REQUESTS.UPDATE_HARDWARE.method,
      `${API_REQUESTS.UPDATE_HARDWARE.path}`,
      hardware
    );
  },
  deleteHardware: (hardwareId) => {
    return sendRequest(
      API_REQUESTS.DELETE_HARDWARE.method,
      API_REQUESTS.DELETE_HARDWARE.path,
      { hardwareId }
    );
  },
  getHardwareByUserIdAndHwid: (hwid) => {
    return sendRequest(
      API_REQUESTS.Get_Hardware_By_Hwid.method,
      API_REQUESTS.Get_Hardware_By_Hwid.path,
      null,
      null,
      { hwid }
    );
  },
  getHardwaresFromStripe: () => {
    return sendRequest(
      API_REQUESTS.GET_HARDWARES_FROM_STRIPE.method,
      API_REQUESTS.GET_HARDWARES_FROM_STRIPE.path
    );
  },

  getActiveHardwares: (data) => {
    return sendRequest(
      API_REQUESTS.GET_ACTIVE_HARDWARES.method,
      API_REQUESTS.GET_ACTIVE_HARDWARES.path,
      data
    );
  },
  getUnassignedHardwares: () => {
    return sendRequest(
      API_REQUESTS.GET_UNASSIGNED_HARDWARES.method,
      API_REQUESTS.GET_UNASSIGNED_HARDWARES.path
    );
  },
  
};
