import React, { useEffect, useMemo, useState } from 'react';
import BillingHistory from '../components/BillingHistory/BillingHistory';
import EditProfile from '../components/EditProfile/EditProfile';
import { PaymentInfoSection } from '../components/PaymentInfo/PaymentInfoSection';
import { Tabs as AccountTabs } from '../components/Tabs/Tabs';
import { AuthApi } from '../lib/auth.api';
import Settings from './AccountSettings';

const MyAccount = () => {
  const [userData, setUsersData] = useState(null);
  const [currentTab, setCurrentTab] = useState('ACCOUNT');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await AuthApi.getUser();
        setUsersData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const userName = userData?.user?.username;

  const sectionTabs = [
    { title: 'Account', identifier: 'ACCOUNT' },
    { title: 'Billing History', identifier: 'BILLINGHISTORY' },
    { title: 'Payment Info', identifier: 'PAYMENTINFO' },
    { title: 'Settings', identifier: 'SETTINGS' },
  ];

  const handleTabsClick = (identifier) => {
    setCurrentTab(identifier);
  };

  const accountTabOptions = useMemo(() => ({
    active: currentTab,
    fullWidth: true,
    tabs: sectionTabs.map(tab => ({ name: tab.title, identifier: tab.identifier })),
    handleClick: handleTabsClick,
  }), [currentTab]);

  const renderTabContent = () => {
    switch (currentTab) {
      case 'ACCOUNT':
        return <EditProfile />;
      case 'BILLINGHISTORY':
        return <BillingHistory />;
      case 'PAYMENTINFO':
        return <PaymentInfoSection />;
      case 'SETTINGS':
        return <Settings />;
      default:
        return null;
    }
  };



  return (
    <>
      <div className="p-5 xl:w-4/5">
        <h1 className="text-2xl font-bold p-3">Hello {userName}</h1>
        <AccountTabs {...accountTabOptions} />
      </div>
      <div className="flex flex-col w-full xl:w-4/5 px-5">
        {renderTabContent()}
      </div>
    </>
  );
};

export default MyAccount;