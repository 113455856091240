import { Dialog, Transition } from '@headlessui/react'
import {
  ArrowPathIcon,
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  BoltIcon,
  BookOpenIcon,
  Cog8ToothIcon,
  CpuChipIcon,
  CubeTransparentIcon,
  ExclamationCircleIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
  UserGroupIcon,
  UserIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'


import { Fragment, useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { AuthApi } from '../lib/auth.api'
import { Loader } from "./Loader/Loader"
import ProfileMenu from './Menus/ProfileMenu'
const userNavigation = [
  { name: 'Home', href: '/dashboard', icon: <HomeIcon />, current: false },
  { name: 'Start Job', href: '/start', icon: <BoltIcon />, current: false },
  { name: 'Map', href: '/mapview/properties', icon: <MapPinIcon />, current: false },
  { name: 'Jobs', href: '/jobs', icon: <MagnifyingGlassIcon />, current: false },
  { name: 'Clients', href: '/clients', icon: <UserGroupIcon />, current: false },
  { name: 'Settings', href: '/settings', icon: <Cog8ToothIcon />, current: false },
]
const technicianNavigation = [
  { name: 'Start Job', href: '/start', icon: <BoltIcon />, current: false },
  { name: 'Map', href: '/mapview/properties', icon: <MapPinIcon />, current: false },
  { name: 'Jobs', href: '/jobs', icon: <MagnifyingGlassIcon />, current: false },
]
const adminNavigation = [
  { name: 'Map', href: '/adminMap', icon: <MapPinIcon />, current: false },
  { name: 'Versions', href: '/admin/versions', icon: <CubeTransparentIcon />, current: false },
  { name: 'OTA Updates', href: '/admin/updates', icon: <ArrowPathIcon />, current: false },
  { name: 'Errors', href: '/admin/errors', icon: <ExclamationCircleIcon />, current: false },
  { name: 'Hardware', href: '/admin/hardware', icon: <CpuChipIcon />, current: false },

]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function DashboardLayout() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true);
  const [usersData, setUsersData] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await AuthApi.getUser();
        setUsersData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const isTechnician = usersData?.user?.role === 'technician'
  const isAdmin = usersData?.user?.role === 'admin'
  const userDropDown = [
    { name: 'My Account', href: '/myaccount', onClick: () => navigate("/myaccount"), icon: <UserIcon className='w-5' /> },
    { name: 'Tutorials', href: '/tutorials', onClick: () => navigate("/tutorials"), icon: <BookOpenIcon className='w-5' /> },
    { name: 'Sign out', onClick: () => AuthApi.logoutUser(), icon: <ArrowRightOnRectangleIcon className='w-5' /> },
  ]

  const technicianDropDown = [
    { name: 'Profile', href: '/profile', onClick: () => navigate("/profile"), icon: <UserIcon className='w-5' /> },
    { name: 'Sign out', onClick: () => AuthApi.logoutUser(), icon: <ArrowRightOnRectangleIcon className='w-5' /> },
  ]
  const dropdownNavigation = isTechnician || isAdmin ? technicianDropDown : userDropDown

  const navigation = isAdmin ? adminNavigation : isTechnician ? technicianNavigation : userNavigation

  const location = useLocation()
  const [sidebarOpen, setSidebarOpen] = useState(false)

  let oneWordFromName = usersData?.user?.username.length > 15 ? usersData?.user?.username?.split(/\s+/).slice(0, 1).join(" ") : usersData?.user?.username

  if (loading) return <Loader />;

  const Navigation = () => {
    if (!navigation) return null;
    return (
      <>
        {navigation.map((item) => {
          return (
            <Link to={item.href} key={item.name} onClick={() => setSidebarOpen(false)}>
              <div
                key={item.name}
                className={classNames(
                  location.pathname === item.href
                    ? 'bg-white text-blue-500 stroke-blue-500 fill-blue-500'
                    : 'text-gray-600 stroke-gray-600 hover:bg-gray-50 hover:text-blue-500 hover:stroke-blue-500 hover:fill-blue-500',
                  'group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer'
                )}
              >
                <div className="flex items-center gap-5 flex-row">
                  <div className='w-8 h-8'>
                    {item.icon}
                  </div>
                  {item.name}
                </div>
              </div>
            </Link>
          );
        })}
      </>
    )
  }

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 flex z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform opacity-0"
                leaveFrom="translate-x-0 opacity-100"
                leaveTo="-translate-x-full opacity-0"
              >
                <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-shrink-0 flex items-center px-4">
                    <Link to={'/admin/dashboard'}>
                      <img
                        style={{ maxWidth: '200px', marginTop: '8px', marginLeft: '10px' }}
                        src='/smartSpray-logo.png'
                        alt="Smart Spray"
                      />
                    </Link>
                  </div>
                  <div className="mt-5 flex-1 h-0 overflow-y-auto">
                    <nav className="px-2 space-y-1">
                      <Navigation />
                    </nav>
                  </div>
                  <ProfileMenu
                    contractorDropDownOptions={dropdownNavigation}
                    username={'user' && oneWordFromName}
                    avatar='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                    screenType={"small screen"}
                  />
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
            </div>
          </Dialog>
        </Transition.Root>
        {/* mobile sidebar options end */}

        {/* ------------------- sidebar desktop ------------------- */}
        <div className="z-10 hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
          <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 bg-gray-50 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <Link to={'/dashboard'}>
                <img
                  style={{ maxWidth: '184px', marginLeft: '10px' }}
                  src='/smartSpray-logo.png'
                  alt="Smart Spray"
                />
              </Link>
            </div>
            <div className=" z-50 flex-grow flex flex-col justify-between px-3 pt-12 pb-5">
              <nav className="px-2 space-y-1">
                <Navigation />
              </nav>

            </div>
            <div className='z-50 absolute w-[224px] bottom-5 right-[21px]'>
              <ProfileMenu
                contractorDropDownOptions={dropdownNavigation}
                username={'user' && oneWordFromName}
                avatar='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                screenType={"large screen"}
              />

            </div>
          </div>
        </div>
        {/* the sidebar-----------------------------  */}

        <div className="lg:pl-64 flex flex-col z-0 flex-1" style={{ height: '100vh' }}>
          <div className="sticky top-0 z-10 flex-shrink-0 flex items-center h-16 lg:h-0 bg-white border border-gray-100 lg:border-0">
            <button
              type="button"
              className="px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 lg:hidden"

              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex">
              </div>
              <div className="ml-4 flex items-center md:ml-6">
              </div>
            </div>
          </div>

          <main className="flex-1 z-0 bg-white"
            style={
              { flexDirection: "column", display: "flex" }
            }>
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
}