import { ArrowLeftIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PrimaryButton } from '../../components/Button/PrimaryButton';
import { AddEditForm } from '../../components/hardware/addEditForm';
import { Loader } from '../../components/Loader/Loader';
import { useApiOperation } from '../../hooks/useApiOperation';
import { AuthApi } from '../../lib/auth.api';
import { HardwaresApi } from '../../lib/hardwares.api';
import AddNewHardware from './AddNewHardware';

export default function AddEditHardware() {
  const navigate = useNavigate()
  const { hwid, isNewHardware } = useParams();
  const { startApiOperation, terminateApiOperation, apiLoading } = useApiOperation()
  const { startApiOperation: fetchHardwareStartApiOperation, terminateApiOperation: fetchHardwareTerminateApiOperation, apiLoading: fetchHardwareApiLoading } = useApiOperation()
  const [selectedHardware, setSelectedHardware] = useState({});
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

  const [user, setUser] = useState();

  const fetchHadwareByID = async () => {
    try {
      fetchHardwareStartApiOperation(true)
      const response = await HardwaresApi.getHardwareByUserIdAndHwid(hwid)
      setSelectedHardware(response.data)
      fetchHardwareTerminateApiOperation(false)
    } catch (error) {
      console.error('error: ', error);
      fetchHardwareTerminateApiOperation(false)
    }
  }

  const fetchUser = async () => {
    try {

      const response = await AuthApi.getUser()
      setUser(response.user)

    } catch (error) {
      console.error('error: ', error);
      fetchHardwareTerminateApiOperation(false)
    }
  }

  useEffect(() => {
    fetchHadwareByID();
    fetchUser();
  }, [hwid])

  if (fetchHardwareApiLoading) return <Loader />

  const displayAddFormWithErrors = () => {
    if (selectedHardware.isDeleted === true || Object.keys(selectedHardware).length === 0) {
      return (
        <div className='w-full h-screen flex flex-col justify-center items-center'>
          <XCircleIcon className='size-20 text-red-500' />
          <h1 className="text-2xl font-bold text-center">
            Hardware Does not Exist
          </h1>
        </div>
      )
    }
    else if (selectedHardware.name && selectedHardware.description) {
      return (
        <div className='w-full h-screen flex flex-col justify-center items-center'>
          <CheckCircleIcon className='size-20 text-green-500' />
          <h1 className="text-2xl font-bold text-center">
            You Have Already Assigned the Required Information
          </h1>
        </div>
      )
    }
    else {
      return (
        <Elements stripe={stripePromise}>
          <AddNewHardware />
        </Elements>
      )
    }
  }


  const HardwareContent = () => {
    if (isNewHardware == "true") {
      return displayAddFormWithErrors()
    } else {
      return (
        <>
          <h1 className="text-2xl font-bold">Edit Hardware</h1>
          <Elements stripe={stripePromise}>
          <AddEditForm
            hardware={selectedHardware}
            actionType="update"
            loading={apiLoading}
            isDefault={user?.defaultHardware === selectedHardware._id ? "Yes" : "No"}
          />
          </Elements>
        </>
      );
    }
  };

  return (
    <>
      {isNewHardware == undefined ?
        <PrimaryButton icon={<ArrowLeftIcon className="w-4 h-4" />} label={"Go Back"} onClickEvent={() => navigate(`/settings`, { state: { activeTab: 'HARDWARE', openModal: false, hwid } })} classNames='text-gray-400 w-fit m-8 border bg-transparent hover:bg-gray-100' /> : null
      }
      <div className='mt-10 lg:w-1/2 mx-auto'  >
        <HardwareContent />
      </div>
    </>
  );
}