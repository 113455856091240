import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { JobDetails } from '../../../components/MapView/jobDetail';
import { useApiOperation } from '../../../hooks/useApiOperation';
import { JobsApi } from '../../../lib/jobs.api';
import MapLayout from '../main-layout';
import { Loader } from '../../../components/Loader/Loader';

const JobDetail = () => {
  const { id } = useParams();
  const [propertyId, setPropertyId] = useState('')
  const [searchParams] = useSearchParams()
  const { startApiOperation, terminateApiOperation, apiLoading } = useApiOperation()
  const [job, setJob] = useState('')

  useEffect(() => {
    fetchJobById()
  }, [searchParams]);

  const fetchJobById = async () => {
    try {
      startApiOperation()
      const data = await JobsApi.getJobById({ jobId: id });
      setPropertyId(data?.address?._id)
      setJob(data);
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation(['An error occurred while fetching jobs on property. Please try again later.'])
    }
  };
  const RenderComp = () => {
    if (apiLoading) return <Loader />
    return <JobDetails job={job} />
  }
  return (
    <MapLayout propertyId={propertyId} setPropertyId={setPropertyId} jobsData={job}>
      <div className="bg-white rounded-lg md:w-1/4 w-full lg:w-2/5 min-w-[250px] mt-6 sm:mt-0 overflow-auto border">
        <RenderComp />
      </div>
    </MapLayout>
  )
}

export default JobDetail
