import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  csvData: [], 
  csvFile: null,
  step2SelectedData: [], 
  selectedRows: [], 
};
const csvDataSlice = createSlice({
    name: 'csvData',
    initialState,
    reducers: {
      setCsvData: (state, action) => {
        state.csvData = action.payload;
      },
      setCsvFile:(state, action) => {
        state.csvFile = action.payload  
      },
      setStep2SelectedData: (state, action) => {
        state.step2SelectedData = action.payload;
      },
      setSelectedRows: (state, action) => {
        state.selectedRows = action.payload;
      },
      setStep3ExcludedCols: (state, action) => {
        state.step3ExcludedCols = action.payload;
      },
      clearCsvDataSlice: (state) => {
        state.csvData = [];
        state.csvFile = null;
        state.step2SelectedData = [];
        state.selectedRows = [];
      },
    },
  });
  

export const { setCsvData, setStep2SelectedData, setSelectedRows, setStep3ExcludedCols ,setCsvFile,clearCsvDataSlice} = csvDataSlice.actions;

export default csvDataSlice.reducer;
