
import { CalendarDateRangeIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useApiOperation } from '../../hooks/useApiOperation';
import { StripeApi } from '../../lib/stripe.api';
import { formatDateWithoutTime } from '../../utils/formateDate';
import { Table } from '../../utils/shared/table/table';
import { PrimaryButton } from '../Button/PrimaryButton';

const BillingHistory = () => {

    const { startApiOperation: fetchStartApiOperation, terminateApiOperation: fetchTerminateApiOperation, apiLoading: fetchApiLoading } = useApiOperation()
    const { startApiOperation: loadMoreStartApiOperation, terminateApiOperation: loadMoreTerminateApiOperation, apiLoading: loadMoreApiLoading } = useApiOperation()

    const [billingRecords, setBillingRecords] = useState([]);
    const [sortByDate, setSortByDate] = useState(false);
    const [nextCursor, setNextCursor] = useState(null);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        fetchBillingHistory();
    }, [sortByDate]);

    const fetchBillingHistory = async (nextCursor = null, isLoadMore = false) => {
        try {
            isLoadMore ? loadMoreStartApiOperation()
                : fetchStartApiOperation()
            const requestParams = {
                ...(sortByDate && { sort: 'date' }),
                ...(nextCursor && { starting_after: nextCursor }),
            };

            const params = Object.keys(requestParams).length > 0
                ? new URLSearchParams(requestParams)
                : undefined;

            const response = await StripeApi.getBillingHistory(params || '');

            setBillingRecords(prevRecords =>
                nextCursor ? [...prevRecords, ...response.invoices] : response.invoices
            );

            setHasMore(response.has_more);
            setNextCursor(response.next_cursor);
        } catch (error) {
            console.error('Error fetching billing history:', error);
        } finally {
            isLoadMore ? loadMoreTerminateApiOperation() : fetchTerminateApiOperation();
        }
    };

    const clearFilters = () => {
        setSortByDate(false);
        setBillingRecords([]);
        fetchBillingHistory();
    };

    const loadMore = () => {
        if (hasMore && nextCursor) {
            fetchBillingHistory(nextCursor, true);
        }
    };

    const tableHeaders = [
        { name: 'Invoice Number', ref: 'invoice' },
        { name: 'Date', ref: 'date' },
        { name: 'Paid Amount', ref: 'amount' },
        { name: 'Actions', ref: 'actions' },
    ];

    const tableData = billingRecords.map((record) => ({
        invoice: record.invoice,
        date: formatDateWithoutTime(record.date),
        amount: "$" + record.amount,
        actions: <a href={record.link} target="_blank" rel="noopener noreferrer" className='px-4 py-2 bg-gray-200 rounded hover:bg-gray-300'>View Invoice</a>
    }));
    const RenderButton = () => {
        if (hasMore)
            return <div className="flex justify-center  mt-4">
                <PrimaryButton label={"Load More"} onClickEvent={loadMore} isDisabled={loadMoreApiLoading} />
            </div>

    }
    const RenderBillingHistory = () => {
        if (fetchApiLoading) {
            return (
                <div className='py-10 text-center'>
                    <h1 className='text-xl font-bold'>Loading billing history...</h1>
                </div>
            );
        }

        if (billingRecords.length > 0) {


            return (
                <>
                    <Table headers={tableHeaders} tabledata={tableData} />
                    <RenderButton />

                </>
            );
        }

        return (
            <div className='py-10 text-center'>
                <h1 className='text-2xl font-bold'>We could not find any billing history</h1>
            </div>
        );
    };
    const RenderClearButton = () => {
        if (sortByDate) return <button
            onClick={clearFilters}
            className='flex place-items-center gap-3 cursor-pointer border rounded-lg px-4 py-1 text-xs bg-red-500 text-white'
        >
            Clear Filters
        </button>
    }

    return (
        <div className='p-5 bg-white rounded-lg shadow-md'>
            <div className='flex justify-between items-center px-3'>
                <h1 className='text-lg font-bold'>Billing History</h1>
                <div className='flex gap-2'>
                    <button
                        onClick={() => setSortByDate(!sortByDate)}
                        className='flex place-items-center gap-3 cursor-pointer border rounded-lg px-4 py-1 text-xs'
                    >
                        Sort by Date <CalendarDateRangeIcon className='h-5 w-5' />
                    </button>
                    <RenderClearButton />
                </div>
            </div>
            <hr className='my-5 bg-gray-100' />
            <RenderBillingHistory />
        </div>
    );
};

export default BillingHistory;