import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Table } from '../../utils/shared/table/table';
import { SecondaryButton } from '../Button/SecondaryButton';
import { DeletePopup } from '../Popups/DeletePopup';
import EditChemical from './editChemical';

const ListChemicals = ({ chemicalsData, selectedChemical, setSelectedChemical, editModal, setEditModal, deleteModal, setDeleteModal, deleteSelected, reFetch }) => {
  const handleEdit = (chemical) => {
    setSelectedChemical(chemical);
    setEditModal(true)
  }

  const handleDelete = (chemicalId) => {
    setSelectedChemical(chemicalId)
    setDeleteModal(true)
  }

  const getChemicalsHeader = () => {
    return [
      { name: 'Name', ref: 'name' },
      { name: 'Description', ref: 'description' },
      { name: 'Quantity', ref: 'quantity' },
      { name: 'Edit', ref: 'edit' },
      { name: 'Delete', ref: 'delete' },
    ];
  };

  const getChemicalsData = () => {
    if (chemicalsData?.length <= 0) return [];
    const currentData = [];

    chemicalsData?.forEach((chemical) => {
      currentData.push({
        name: chemical.chemicalName,
        description: (<p dangerouslySetInnerHTML={{ __html: chemical.description }}></p>),
        quantity: `${chemical.concentration} ${chemical.measurement}`,
        edit: (
          <div className='mt-3 flex justify-start items-center'>
            <SecondaryButton
              label='Edit'
              icon={<PencilIcon className='w-4 text-gray-500 ' />}
              onClick={() => handleEdit(chemical)}

            />
          </div>
        ),
        delete: (
          <div className='mt-3 flex gap-x-3 md:gap-x-4 justify-start items-center'>
            <SecondaryButton
              label='Delete'
              icon={<TrashIcon className='w-4 text-gray-500' />}
              onClick={() => handleDelete(chemical)}
            />
          </div>
        ),
        actions: <></>,
      });
    });
    return currentData;
  };

  const tabledata = {
    tabledata: getChemicalsData(),
    headers: getChemicalsHeader(),
  };

  return (
    <div>
      <DeletePopup
        onDelete={deleteSelected}
        label='Delete Chemical'
        desc='Are you sure you want to delete this chemical?'
        open={deleteModal}
        setOpen={setDeleteModal}
      />
      <EditChemical
        reFetch={reFetch}
        selectedChemical={selectedChemical}
        setSelectedChemical={setSelectedChemical}
        openModal={editModal}
        setOpenModal={setEditModal}
      />
      <div className='m-5'>
        <Table {...tabledata} />
      </div>
    </div>
  )
}

export { ListChemicals };

