import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../components/Button/PrimaryButton';
import AddHardware from '../../components/hardware/addHardware';
import { ListHardwares } from '../../components/hardware/renderCards';
import { Loader } from '../../components/Loader/Loader';
import { ErrorAlert } from '../../components/Popups/ErrorAlert';
import { setNotifications } from '../../features/notificationsSlice';
import { useApiOperation } from '../../hooks/useApiOperation';
import { HardwaresApi } from '../../lib/hardwares.api';
import { NOTIFY_TYPE } from '../../utils/constants';

// Test: For deployment remove when you see it 

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Hardware = () => {
    const location = useLocation();
    const { apiLoading, startApiOperation, terminateApiOperation, apiError } = useApiOperation();
    const [selectedHardware, setSelectedHardware] = useState({});
    const [hardwaresData, setHardwaresData] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [showAddHardware, setShowAddHardware] = useState(false);
    const [modalOpen, setModalOpen] = useState(false); // New state for modal trigger
    const [hwid, setHwId] = useState();
    const [hardwaresList, setHardwaresList] = useState([]);
    const [selectedHardwareFromMenu, setSelectedHardwareFromMenu] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    
    useEffect(() => {
        fetchHardwares();
        fetchHardwaresFromStripe();
        // If redirected from activation, show the modal and trigger it
        if (location.state?.openModal) {
            setShowAddHardware(true);
            setModalOpen(true);
        }
        if (location.state?.hwid) {
            const id = location.state?.hwid
            setHwId(id)
        }

    }, [location]);

    const fetchHardwares = async () => {
        try {
            startApiOperation();
            const responseData = await HardwaresApi.getActiveHardwares();
            setHardwaresData(responseData);
            terminateApiOperation();
        } catch (error) {
            terminateApiOperation(['Error fetching data']);
        }
    };

    const fetchHardwaresFromStripe = async () => {
        try {
            startApiOperation();
            const responseData = await HardwaresApi.getHardwaresFromStripe();
            setHardwaresList(responseData);
            terminateApiOperation();
        } catch (error) {
            terminateApiOperation(['Error fetching data']);
        }
    };


    const deleteSelectedHardwares = async () => {
        try {
            startApiOperation();
            const { _id } = selectedHardware;
            await HardwaresApi.deleteHardware(_id);
            fetchHardwares();
            setDeleteModal(false);
            terminateApiOperation();
        } catch (error) {
            terminateApiOperation([error]);
        }
    };

    const DisplayLoader = () => apiLoading ? <Loader /> : null;
    const DisplayError = () => apiError ? <ErrorAlert errorMessages={apiError} /> : null;

    const validateInputs = () => {
        const errors = [];
        if (!selectedHardwareFromMenu) errors.push('Please Select a Hardware ');
        return errors;
    };

    const handleRedirectToStripe = async () => {
        try {
            const errors = validateInputs();
            if (errors.length) {
                dispatch(setNotifications({ type: NOTIFY_TYPE.Error, messages: errors }));
                return;
            }
            navigate("/purchaseHardware", { state: selectedHardwareFromMenu })
        } catch (error) {
            console.error("Error redirecting to stripe:", error);
        }
    };

    const ShowAddButton = () => {
        if (!showAddHardware) return <PrimaryButton
            classNames='text-white'
            label="Add hardware"
            onClickEvent={handleRedirectToStripe}
            displayPlus
        />
        return <AddHardware refetch={fetchHardwares} isOpen={modalOpen} setOpen={setModalOpen} HwId={hwid} />
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    return (
        <div>
            <DisplayLoader />
            <DisplayError />
            <div className='w-full flex flex-wrap justify-end items-center gap-4'>


                <Elements stripe={stripePromise}>
                    <Menu
                        id="source"
                        as="div"
                        className="w-full md:w-2/6 relative inline-block text-left p-0"
                    >
                        <div className="flex justify-between">
                            <Menu.Button className="inline-flex justify-start w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm lg:font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500">
                                <div className="w-full text-left">
                                    {!selectedHardwareFromMenu?.name && "Select Hardware"}
                                    {selectedHardwareFromMenu?.name && selectedHardwareFromMenu?.name}
                                </div>
                                <ChevronDownIcon
                                    className="-mr-1 ml-2 h-5 w-5"
                                    aria-hidden="true"
                                />
                            </Menu.Button>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                    {hardwaresList?.data?.map((item) => (
                                        <Menu.Item
                                            as="div"
                                            key={item.id} // Unique key to prevent warnings
                                            onClick={() => {
                                                setSelectedHardwareFromMenu(item);
                                            }}
                                        >
                                            {({ active }) => (
                                                <div
                                                    className={classNames(
                                                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                                        "block px-4 py-2 text-sm truncate"
                                                    )}
                                                >
                                                    {item.name}
                                                </div>
                                            )}
                                        </Menu.Item>
                                    ))}
                                </div>

                            </Menu.Items>
                        </Transition>
                    </Menu>
                </Elements>
                <ShowAddButton />
            </div>
            <ListHardwares
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                editModal={editModal}
                setEditModal={setEditModal}
                hardwaresData={hardwaresData}
                deleteSelected={deleteSelectedHardwares}
                selectedHardware={selectedHardware}
                setSelectedHardware={setSelectedHardware}
                reFetch={fetchHardwares}
            />
        </div>
    );
};

export default Hardware;
