import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import React, { Fragment, useEffect, useState } from 'react'
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal'
import { Table } from '../../components/Table/Table'
import { useApiOperation } from '../../hooks/useApiOperation'
import { AuthApi } from '../../lib/auth.api'
import { HardwaresApi } from '../../lib/hardwares.api'

const Technicians = () => {
  const [email, setEmail] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [technicians, setTechnicians] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [technicianToDelete, setTechnicianToDelete] = useState("")
  const [userData, setUserData] = useState(null);
  const { startApiOperation, terminateApiOperation } = useApiOperation()
  const [hardware, setHardware] = useState();
  const [selectedHardware, setSelectedHardware] = useState({ _id: "", name: "" });

  const getTechnicianHeader = () => {
    return [
      { name: "Name", ref: "name" },
      { name: "Role", ref: "role" },
      { name: "Status", ref: "status" },
      { name: "Hardware", ref: "hardware" },
    ];
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await AuthApi.getUser();
        setUserData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchHardware = async () => {
      startApiOperation();
      const res = await HardwaresApi.getActiveHardwares({isNamePresent:false});
      setHardware(res);
    }

    getAllTechnicians();
    fetchData();
    fetchHardware();
  }, []);

  useEffect(() => {
  }, [userData, technicians]);

  useEffect(() => {
    AuthApi.getUserTechnicians();
  }, []);

  const getAllTechnicians = async () => {
    try {
      const response = await AuthApi.getUserTechnicians();
      if (response && Array.isArray(response)) {
        setTechnicians(response); // Update state
      } else {
        console.warn("Unexpected response structure or no data received from API");
        setTechnicians([]); // Set an empty array as a fallback
      }
    } catch (error) {
      console.error("Error fetching technicians:", error.response || error.message);
    }
  };

  const technicianData = () => {
    const currentData = [];

    technicians?.length &&
      technicians?.map((technician) => {
        currentData.push({
          name: technician?.technicianId?.username,
          role: technician?.technicianId?.role,
          status: (
            <span
              className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${technician?.technicianId?.status === "Invite Sent"
                ? "bg-red-100 text-red-800"
                : "bg-green-100 text-green-800"
                } `}
            >
              {technician?.technicianId?.status}
            </span>
          ),
          hardware: (
            technician?.hardwareId?.name || technician?.hardwareId?._id
          ),
          actions: (
            <>
              <button
                onClick={async () => {
                  setShowModal(true);
                  setTechnicianToDelete(technician?._id);
                }}
                className="px-5 py-2 bg-red-100 rounded-md hover:bg-red-50 hover:text-red-500"
              >
                Delete
                <span className="sr-only">
                  , {technician?.technicianId?.name}
                </span>
              </button>
            </>
          ),
        });
      });

    return currentData
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validateInputs = () => {
    const errors = [];

    if (!email) {
      errors.push('Email is required');
    } else if (!emailRegex.test(email)) {
      errors.push('Email is invalid');
    }

    if (!selectedRole) errors.push('Role is required');

    return errors;
  };

  const submitData = async () => {
    startApiOperation();

    const errors = validateInputs();
    if (errors.length) {
      terminateApiOperation(errors);
      return;
    }

    try {
      const password = generateRandomPassword(10);

      await AuthApi.createUserTechnicians(
        email,
        selectedRole,
        email.split('@')[0],
        password,
        password,
        "Invite Sent",
        userData?.user?._id,
        selectedHardware.id,
      );

      await getAllTechnicians();
      setEmail("");
      setSelectedRole("");

    } catch (error) {
      console.error("Error creating technician:", error);
    } finally {
      terminateApiOperation();
    }
  };


  const tabledata = {
    tabledata: technicianData(),
    headers: getTechnicianHeader(),
    // isEdit: isEdit
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleDeleteTechnician = async (technicianId) => {
    await AuthApi.deleteTechnician(technicianId);
    await getAllTechnicians();
    setShowModal(false);
  };

  const generateRandomPassword = (length) => {
    const alphabets =
      "abcdefghijkl~!@#$mnopqrs0123tuvwxyz%^&*ABCDEF456GHIJKL_+MNOPQRST789UVWXYZ`?";
    let password = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * alphabets.length);
      password += alphabets[randomIndex];
    }

    return password;
  };

  return (
    <div>
      {showModal && <ConfirmationModal onBack={() => setShowModal(false)} onConfirm={() => handleDeleteTechnician(technicianToDelete)}>
        <div>
          <p className='mb-0 font-normal'>Are you sure you want delete technician?</p>
        </div>
      </ConfirmationModal>}
      <div className="space-y-6 p-3 bg-white border-[1px] border-gray-100 rounded-lg xl:w-4/5">
        <div className="px-0 md:px-3">
          <h1 className="text-gray-900 text-xl font-bold my-auto mb-3">
            Technicians
          </h1>
          <p className="mb-0 mt-1 text-sm text-gray-700">
            Add technicians to your account.
          </p>
          <p className="mb-0 mt-1 text-sm text-gray-700">
            technicians have access to start job and view completed jobs.
          </p>
        </div>
        <div className="space-y-1">
          <label
            htmlFor="add-team-members"
            className="block text-sm font-medium text-gray-700"
          >
            Add Technicians
          </label>
        </div>

        <div className="flex flex-col items-center justify-center md:flex-row gap-1.5 md:gap-3">
          <div className="flex w-full p-0">
            <input
              type="email"
              required
              name="add-team-members"
              id="add-team-members"
              className="w-full outline-none h-8 outline-gray-200 focus:outline-green-500 rounded-md p-2"
              placeholder="Email address"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              aria-describedby="add-team-members-helper"
            />
          </div>
          <Menu
            id="source"
            as="div"
            className="w-full md:w-2/6 relative inline-block text-left p-0"
          >
            <div className="flex justify-between">
              <Menu.Button className="inline-flex justify-start w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm lg:font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500">
                <div className="w-full text-left">
                  {!selectedRole && "Select Role"}
                  {selectedRole && selectedRole}
                </div>
                <ChevronDownIcon
                  className="-mr-1 ml-2 h-5 w-5"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item as="div" onClick={() => {
                    setSelectedRole("technician");
                  }}>
                    {({ active }) => (
                      <div
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Technician
                      </div>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

          {/* ADD HARDWARE */}
          <Menu
            id="source"
            as="div"
            className="w-full md:w-2/6 relative inline-block text-left p-0"
          >
            <div className="flex justify-between">
              <Menu.Button className="inline-flex justify-start w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm lg:font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500">
                <div className="w-full text-left">
                  {!selectedHardware?.name && "Select Hardware"}
                  {selectedHardware?.name && selectedHardware?.name}
                </div>
                <ChevronDownIcon
                  className="-mr-1 ml-2 h-5 w-5"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {hardware?.map((item) => (
                      <Menu.Item
                        as="div"
                        key={item._id} // Unique key to prevent warnings
                        onClick={() => {
                          setSelectedHardware({ id: item._id, name: item.name || item._id });
                        }}
                      >
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                              "block px-4 py-2 text-sm truncate"
                            )}
                          >
                            {item.name || item._id}
                          </div>
                        )}
                      </Menu.Item>

                    ))}
                </div>

              </Menu.Items>
            </Transition>
          </Menu>



          <span className="ml-0 p-0">
            <button
              onClick={submitData} type="button"
              className="bg-white inline-flex w-full md:w-full items-center px-4 py-2 border border-gray-300 shadow-sm text-sm mx-auto font-medium rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              <span className="text-center">Add</span>
            </button>
          </span>
        </div>
        {
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <Table {...tabledata} />
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default Technicians;
