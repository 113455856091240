import React from 'react';

const RenderOptions = ({options, displayValue}) => {
  return options.map((option) => (
    <option key={option.value} value={option.value}>
      {option[displayValue]}
    </option>
  ));
};

const SingleSelect = ({ options, displayValue, currentValue, onChange, placeholder }) => {
  return (
    <div className="w-full">
      <select
        value={currentValue?.value || ''}
        onChange={(e) => {
          const selectedOption = options.find(option => option.value === e.target.value);
          onChange(selectedOption);
        }}
        className="w-full rounded-md border border-gray-300 p-2 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-200 bg-transparent"
      >
        <option value="" disabled>{placeholder}</option>
        <RenderOptions options={options} displayValue={displayValue} />
      </select>
    </div>
  );
};

export { SingleSelect };
