import { PencilIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SecondaryButton } from '../../components/Button/SecondaryButton';
import { Loader } from '../../components/Loader/Loader';
import { ErrorAlert } from '../../components/Popups/ErrorAlert';
import { useApiOperation } from '../../hooks/useApiOperation';
import { HardwaresApi } from '../../lib/hardwares.api';


function AdminHardware() {
  const { apiLoading, startApiOperation, terminateApiOperation, apiError } = useApiOperation();
  const [hardwaresData, setHardwaresData] = useState([]);

  const navigate = useNavigate();

  const handleEditClick = (hardware) => {
    navigate(`/admin/editAdminHardware/${hardware._id}/${hardware.userId._id}`);
  }

  useEffect(() => {
    fetchHardwares();
  }, []);


  const fetchHardwares = async () => {
    try {
      startApiOperation();
      const responseData = await HardwaresApi.getUnassignedHardwares();
      setHardwaresData(responseData);
      terminateApiOperation();
    } catch (error) {
      terminateApiOperation(['Error fetching data']);
    }
  };


  const DisplayLoader = () => apiLoading ? <Loader /> : null;
  const DisplayError = () => apiError ? <ErrorAlert errorMessages={apiError} /> : null;


  return (
    <div>
      <DisplayLoader />
      <DisplayError />
      <section className="mt-10 px-10">
        <div className="px-4 sm:flex-auto">
          <h1 className="text-3xl font-medium text-gray-900">New Hardwares</h1>
        </div>
        <div className='grid grid-cols-1 p-5 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-x-[25px] gap-y-6'>
          {hardwaresData.data?.map((item) => (
            <div className='col-span-1 rounded-lg bg-white shadow border border-gray-300 w-full min-h-[80px] flex flex-col h-full'>
              {/* Header and Content */}
              <div className='px-4 pt-4 flex-grow flex flex-col'>
                <h3 className='font-semibold text-sm font-inter text-[#1A1C1C] leading-5 truncate'>{item.userId.username}</h3>
                <p className='text-sm text-blue-500 mt-2'>{item.userId.email}</p>
              </div>
              {/* Footer Buttons */}
              <div className='px-4 pb-4 flex-shrink-0'>
                <div className='mt-3 flex gap-x-3 md:gap-x-4 justify-start items-center'>
                  <SecondaryButton label='Edit' icon={<PencilIcon className='w-4 text-gray-500' />} onClick={()=>handleEditClick(item)} />
                </div>
              </div>
            </div>
          ))}

        </div>
      </section>
      <div>


      </div>
    </div>


  )
}

export default AdminHardware