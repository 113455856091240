import { useState, useEffect, useRef } from 'react'
import { TrashIcon, PencilIcon } from '@heroicons/react/24/outline'
import { SecondaryButton } from '../Button/SecondaryButton'
import { BookmarkIcon } from '@heroicons/react/24/solid'

export default function Card({ name, description, onEditClick, hwid, onDeleteClick, marker }) {
    const [isExpanded, setIsExpanded] = useState(false)
    const [isOverflowing, setIsOverflowing] = useState(false)
    const descriptionRef = useRef(null)

    const toggleExpand = () => setIsExpanded(!isExpanded)

    useEffect(() => {
        if (descriptionRef.current) {
            setIsOverflowing(descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight)
        }
    }, [description])
    const RenderBookmarkIcon = () => {
        if (!marker) return null
        return <BookmarkIcon className='w-7 text-blue-600' />

    }
    const RenderToggleButton = () => {
        if (!isOverflowing) return null
        return (
            <button onClick={toggleExpand} className='text-sm mt-1 text-blue-500'>
                {isExpanded ? 'See Less' : 'See More'}
            </button>
        )
    }
    return (
        <div className='col-span-1 rounded-lg bg-white shadow border border-gray-300 w-full min-h-[160px] flex flex-col h-full'>
            {/* Header and Content */}
            <div className='px-4 pt-4 flex-grow flex flex-col'>
                <h3 className='font-semibold text-sm font-inter text-[#1A1C1C] leading-5 truncate'>{name}</h3>
                <span className='leading-6 hyphens-auto text-gray-500 flex-grow'>
                    <p
                        ref={descriptionRef}
                        dangerouslySetInnerHTML={{ __html: description }}
                        className={`text-sm mt-3 ${isExpanded ? '' : 'line-clamp-2'}`}
                    ></p>
                    <RenderToggleButton />

                    <p className='text-sm text-blue-500 mt-2'>HWID: {hwid}</p>
                </span>
            </div>

            {/* Footer Buttons */}
            <div className='px-4 pb-4 flex-shrink-0'>
                <div className='mt-3 flex gap-x-3 md:gap-x-4 justify-start items-center'>
                    <SecondaryButton label='Edit' icon={<PencilIcon className='w-4 text-gray-500' />} onClick={onEditClick} />
                    <SecondaryButton label='Delete' icon={<TrashIcon className='w-4 text-gray-500' />} onClick={onDeleteClick} />
                    <RenderBookmarkIcon />
                </div>
            </div>
        </div>
    )
}
