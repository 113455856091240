import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import FetchCardInfo from '../../components/hardware/fetchCardInfo';
import { AuthApi } from "../../lib/auth.api";
import { StripeApi } from "../../lib/stripe.api";
import { setNotifications } from '../../features/notificationsSlice';
import { NOTIFY_TYPE } from '../../utils/constants';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CreateCustomerForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const location = useLocation();
    const navigate = useNavigate();
    const selectedHardware = location.state || {};
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const dispatch = useDispatch();

    const fetchUser = async () => {
        try {
            const res = await AuthApi.getUser();
            setUser(res.user);
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    useEffect(() => {
        fetchUser();
    }, []);

    const validateInputs = () => {
        const cardNumberElement = elements.getElement(CardNumberElement);
        const cardExpiryElement = elements.getElement(CardExpiryElement);
        const cardCvcElement = elements.getElement(CardCvcElement);

        if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
            throw new Error("Card details are incomplete. Please enter all required fields.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!user || !stripe || !elements) {
            console.error("Stripe is not initialized or user data is missing.");
            setLoading(false);
            return;
        }

        try {
            let stripeCustomerId = user.stripeCustomerId || null;
            if (!stripeCustomerId) {
                const res = await StripeApi.createStripeCustomer();
                stripeCustomerId = res.customer.id;
            }

            if (isEditing) {
                try {
                    validateInputs();
                } catch (validationError) {
                    dispatch(setNotifications({ type: NOTIFY_TYPE.Error, messages: [validationError.message] }));
                    setLoading(false);
                    return;
                }

                const cardNumberElement = elements.getElement(CardNumberElement);
        
                let { paymentMethod, error } = await stripe.createPaymentMethod({
                    type: "card",
                    card: cardNumberElement,
                    billing_details: {
                        name: user.username,
                        email: user.email,
                    },
                });

                if (error) throw new Error(error.message);

                await StripeApi.attachPaymentMethod({ paymentMethodId: paymentMethod.id, customerId: stripeCustomerId });
            }

            await StripeApi.payInvoice({ stripeCustomerId, priceId: selectedHardware.default_price });

            navigate("/paymentSuccess", { replace: true });
        } catch (error) {
            console.error("Stripe Error:", error);
            dispatch(setNotifications({ type: NOTIFY_TYPE.Error, messages: [error.message] }));
        }

        setLoading(false);
    };
    return (
        <div className="w-full h-screen flex flex-col justify-center items-center">
            <div className="w-1/2 mx-auto p-8 bg-white shadow-lg rounded-xl border border-gray-200">
                <h2 className="text-3xl font-bold text-center text-gray-700 mb-6">Pay With Card</h2>
                <div className="text-center mb-6">
                    <h3 className="text-xl font-semibold text-gray-800">{selectedHardware.name}</h3>
                    <p className="text-lg text-gray-600">${selectedHardware.price}</p>
                </div>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <input
                        type="text"
                        placeholder="Full Name"
                        value={user?.username || ""}
                        readOnly
                        className="p-2 border border-gray-300 rounded-md w-full mb-2"
                    />
                    <input
                        type="email"
                        placeholder="Email Address"
                        value={user?.email || ""}
                        readOnly
                        className="p-2 border border-gray-300 rounded-md w-full mb-2"
                    />
                    <FetchCardInfo actionType="add" isEditing={isEditing} setIsEditing={setIsEditing} />
                    <button
                        type="submit"
                        disabled={!stripe || loading}
                        className="w-full bg-green-500 text-white py-3 rounded-lg font-semibold text-lg hover:bg-green-600 transition disabled:bg-gray-400"
                    >
                        {loading ? "Processing..." : "Pay Now"}
                    </button>
                </form>
            </div>
        </div>
    );
};

const CreateCustomer = () => (
    <Elements stripe={stripePromise}>
        <CreateCustomerForm />
    </Elements>
);

export default CreateCustomer;
