import { ArrowsUpDownIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { mergeClasses } from "../../helpers";
import { CardCheckbox } from "../elements";

const Table = ({
  tabledata,
  headers,
  handleSorting,
  name,
  selectedRows,
  isAllSelected,
  setIsAllSelected,
  setSelectedRows,
  checkboxes = false,
  horizontalScroll = false,
}) => {
  const searchParams = useSearchParams();
  const [sortField, setSortField] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');


  const handleCheckboxChange = (id) => {
    if (!setSelectedRows || !selectedRows) return;
    let updatedSelectedRows = [];
    updatedSelectedRows = selectedRows?.includes(id)
      ? selectedRows.filter((item) => item !== id)
      : [...selectedRows, id];

    setSelectedRows(updatedSelectedRows);
  };

  const handleSelectAllChange = () => {
    if (!setSelectedRows) return;
    let updatedSelected = [];
    if (isAllSelected) {
      setSelectedRows(updatedSelected);
    } else {
      updatedSelected = tabledata?.map((item) => item.id) || [];
      setSelectedRows(updatedSelected);
    }
    if (setIsAllSelected) {
      setIsAllSelected(tabledata?.length === updatedSelected?.length);
    }
  };

  const RenderHeaderCheckboxes = () => {
    if (!checkboxes) return null;
    return (
      <th className="flex w-auto">
        <CardCheckbox
          value={isAllSelected ?? false}
          setValue={handleSelectAllChange}
          cardStyles="py-4"
          checkboxStyles="h-5 w-5 rounded-md"
        />
      </th>
    );
  };

  const RenderBodyCheckboxes = ({ id }) => {
    if (!checkboxes) return null;
    return (
      <td className="flex w-auto">
        <CardCheckbox
          value={isAllSelected ? true : selectedRows?.length ? selectedRows?.includes(id) : false}
          setValue={() => handleCheckboxChange(id)}
          cardStyles="py-4"
          checkboxStyles={`h-5 w-5 rounded-md ${isAllSelected ? ' text-opacity-70' : ''}`}
          disabled={isAllSelected ?? true}
        />
      </td>
    );
  };

  const TableHead = () => (
    <thead>
      <tr>
        <RenderHeaderCheckboxes />
        {headers?.map((each) => (
          <th
            key={each.ref || each.name}
            scope="col"
            className={mergeClasses(
              `text-normal w-auto min-w-min py-2 pl-4 pr-5 text-left font-semibold text-gray-700 sm:pl-0`,
              each.ref === 'export' && 'py-0 pr-0'
            )}
          >
            <div className="ml-5 flex items-center justify-start gap-2">
              {each.name}
              {each?.enableSorting ? (
                <ArrowsUpDownIcon
                  className="cursor-pointer w-4 h-4 text-gray-500 hover:text-gray-900"
                  onClick={() => handleSortingChange(each.ref)}
                />
              ) : null}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );

  const TableBody = () => {
    const MappedTableData = () => {
      if (!tabledata?.length) return <DisplayNotFound />;
      return tabledata.map((each, index) => (
        <tr
          key={index}
          className={` bg-opacity-30 hover:bg-secondary 'bg-white' `}
        >
          <RenderBodyCheckboxes id={index} />
          {Object.keys(tabledata[0])
            .filter((item) => item !== 'key' && item !== 'id' && item !== 'isChecked')
            .map((item, index) => (
              <td
                key={index}
                className={mergeClasses(
                  'w-auto min-w-min py-4 pl-5 pr-5 text-sm text-black break-words whitespace-normal',
                  item === 'export' && '!py-0 pr-0'
                )}
              >
                {each[item] && each[item] !== 'N/A'
                  ? each[item]
                  : each[item] === false
                    ? null
                    : typeof each[item] === 'number'
                      ? 0
                      : '-'}
              </td>
            ))}
        </tr>
      ));
    };
    return (
      <tbody className="w-96 divide-y">
        <MappedTableData />
      </tbody>
    );
  };

  const handleSortingChange = (accessor) => {
    const newSortOrder = sortField === accessor && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(accessor);
    setSortOrder(newSortOrder);

    handleSorting && handleSorting('sortOrder', newSortOrder);

    const params = new URLSearchParams(searchParams.toString());
    params.set('sortField', accessor);
    params.set('sortOrder', newSortOrder);
  };

  const DisplayNotFound = () => {
    return <tr>
      <td
        className="py-10 font-semibold text-gray-500 text-center w-full border"
        colSpan={headers.length}
      >
        <h1 className="text-xl">No {name || 'data'} to display</h1>
      </td>
    </tr>
  }

  return (
    <div
      className={mergeClasses(
        'px-4 sm:px-6 lg:px-8 w-full',
        horizontalScroll ? 'overflow-x-auto overflow-y-hidden' : 'lg:overflow-hidden'
      )}
    >
      <div className="flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="w-full overflow-x-auto">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-0">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHead />
                <TableBody />
              </table>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Table };
