import {
  CardNumberElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApiOperation } from "../../hooks/useApiOperation";
import { AuthApi } from "../../lib/auth.api";
import { HardwaresApi } from "../../lib/hardwares.api";
import { StripeApi } from "../../lib/stripe.api";
import { Input } from "../Input/Input";
import { Loader } from "../Loader/Loader";
import { DropdownMenuHC } from "../Menus/DropdownHC";
import ReactQuillComponent from "../ReactQuillComponent";
import InfoIcon from "../icons/InfoIcon";
import FetchCardInfo from './fetchCardInfo';


const DisplayHardwareFields = ({ hardware, setHardware, HwId, isDefault }) => {
  const { name, description } = hardware;
  const hwid = HwId || hardware.hwid;
  const dropdown = hardware.dropdown ?? isDefault;

  const handleInputChange = (key, value) => {
    setHardware((prevHardware) => ({
      ...prevHardware,
      [key]: value,
    }));
  };

  return (
    <>
      <div className="mt-5">
        <Input
          options={{
            label: "Name",
            name: "name",
            id: "name",
            value: name || "", // Ensuring controlled input
            onChange: (e) => handleInputChange("name", e.target.value),
            type: "text",
          }}
        />
      </div>

      <div className="mt-5">
        <p className="text-[14px]">Description</p>
        <ReactQuillComponent
          content={description || ""}
          setContent={(value) => handleInputChange("description", value)}
        />
      </div>

      <div className="mt-5 flex gap-x-3">
        <Input
          options={{
            label: "HWID",
            name: "hwid",
            id: "hwid",
            value: hwid || "",
            type: "text",
            readOnly: true,
          }}
        />
        <DropdownMenuHC
          label="Make Default?"
          dropdownOptions={[{ name: "Yes" }, { name: "No" }]}
          selector={dropdown}
          setSelector={(value) => handleInputChange("dropdown", value)}
          arrowDirectionUp={true}
        />
      </div>
    </>
  );
};


const AddEditForm = ({
  hardware,
  actionType = "update",
  setIsEditing,
  isEditing,
  HwId,
  isDefault,
}) => {
  const { startApiOperation, terminateApiOperation } = useApiOperation();
  const navigate = useNavigate();
  const [selectedHardware, setSelectedHardware] = useState(hardware);
  const stripe = useStripe();
  const elements = useElements();
  const [loading,setLoading]=useState(false);
  const validateInputs = () => {
    const { name, description } = selectedHardware;
    const errors = [];
    if (!name) errors.push("Hardware name is required");
    if (!description) errors.push("Hardware description is required");

    return errors;
  };

  const handleSubmit = async () => {
    try {
      startApiOperation();
      setLoading(true);
      const errors = validateInputs();
      if (errors.length) {
        terminateApiOperation(errors);
        return;
      }

      if (!stripe || !elements) {
        console.error("Stripe has not been initialized.");
        terminateApiOperation(["Stripe is not initialized."]);
        return;
      }
      if(actionType!="update"){

        // Get Stripe Elements
        const cardNumberElement = elements.getElement(CardNumberElement);
        
        if (!cardNumberElement) {
          console.error("Card elements are missing.");
          terminateApiOperation(["Card details are missing."]);
          return;
        }
        
        const { user } = await AuthApi.getUser();
        if (isEditing) {
          const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: "card",
            card: cardNumberElement,
            billing_details: {
              name: user.username,
              email: user.email,
            },
          });
          
          if (error) {
            console.error("Payment Method Error:", error.message);
            throw new Error(error.message);
          }
          
          const paymentPayload = { paymentMethodId: paymentMethod.id, customerId: user.stripeCustomerId };
          await StripeApi.attachPaymentMethod(paymentPayload);
        }
        
        await StripeApi.paySubscription({ stripeCustomerId: user.stripeCustomerId, priceId: "price_1QXjzULX2Gf2HoJtLjGXA8YM" });
      }

      const payload = {
        ...selectedHardware,
        _id: hardware._id,
        status: "active",
      };

      await HardwaresApi.updateHardware(payload);
      setLoading(false);
      terminateApiOperation(["Hardware Info Updated"], "success");
      navigate("/settings", { state: { activeTab: "HARDWARE", openModal: false } });

    } catch (error) {
      console.error("Error during submission:", error);
      setLoading(false);
      terminateApiOperation(["Something went wrong while updating hardware info. Please try again later."]);
    }
  };




  const memoizedHardwareFields = useMemo(
    () => (
      <DisplayHardwareFields
        hardware={selectedHardware}
        setHardware={setSelectedHardware}
        HwId={HwId}
        isDefault={isDefault}
      />
    ),
    [selectedHardware, HwId, isDefault]
  );

  return (
    <>
      {memoizedHardwareFields}
      <FetchCardInfo actionType={actionType} setIsEditing={setIsEditing} isEditing={isEditing} />
      {actionType === "add" && (
        <div
          className="flex items-center bg-[#f3f4f6] text-blue-500 text-sm font-normal px-2 py-2 mt-5 rounded-md"
          role="alert"
        >
          <InfoIcon />
          <p>
            Upon adding hardware, a monthly subscription fee of $59 will be required to continue using the hardware.
          </p>
        </div>
      )}
      <div className="mt-5 sm:mt-6">
        <button
          type="button"
          disabled={loading}
          className="inline-flex justify-center max-h-10 w-full rounded-md border border-transparent px-4 py-2 bg-blue-500 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
          onClick={handleSubmit}
        >
          {loading ? <Loader height="h-full" width="w-auto" /> : "Submit"}
        </button>
      </div>
    </>
  );
};

export { AddEditForm };
