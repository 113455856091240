import { PlusIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { mergeClasses } from '../../utils/helpers';
import { Loader } from '../Loader/Loader';

const defaultButtonStyles = 'bg-blue-500 px-3 py-2 rounded-lg  text-sm hover:bg-blue-600 transition-all flex items-center gap-x-1.5 min-w-24 justify-center'
export function PrimaryButton({ label, onClickEvent, displayPlus, icon = '', classNames = '', loading = false, btnType = 'button' }) {
    const buttonClasses = mergeClasses(defaultButtonStyles, classNames);


    const ShowIcon = () => {
        if (displayPlus) return <PlusIcon className='w-4' />
        return null
    }
    const RenderChildren = () => {
        if (loading) {
            return <Loader height='h-2' width='h-2' />
        }
        return (
            <>
                <ShowIcon />
                <span>{icon}</span>
                <span className='font-medium'>
                    {label}
                </span>
            </>
        )
    }
    return (
        <button type={btnType} disabled={loading} className={buttonClasses} onClick={onClickEvent}>
            <RenderChildren />
        </button>
    )
}
