import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Input } from '../../components/Input/Input';
import { Loader } from '../../components/Loader/Loader';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { PrimaryButton } from '../../components/Button/PrimaryButton';
import { useApiOperation } from '../../hooks/useApiOperation';
import { HardwaresApi } from '../../lib/hardwares.api';
import { NOTIFY_TYPE } from '../../utils/constants';

export const EditAdminHardware = () => {
    const { id, userId } = useParams();
    const [hwid, setHwid] = useState('');
    const [loading, setLoading] = useState(false);
    const { startApiOperation, terminateApiOperation, apiError } = useApiOperation();

    const navigate = useNavigate();

    const validateInputs = () => {
        const errors = [];
        if (!hwid) errors.push('Hardware ID is required');
        return errors;
    }


    const handleSubmit = async () => {
        const payload = {
            _id: id,
            hwid,
            sendEmail: true,
            userId,
            status:"pending",
            isAssignHwid:true
        }
        try {
            startApiOperation()
            const errors = validateInputs();
            setLoading(true);
            if (errors.length) {
                setLoading(false);
                return terminateApiOperation([errors])
            }
            await HardwaresApi.updateHardware(payload);
            terminateApiOperation(['Hardware Info Updated'], NOTIFY_TYPE.Success)

            navigate(`/admin/hardware`)
        } catch (error) {
            console.log(error);
            terminateApiOperation([
                'Hardware HWID already Exist. Please Provide a New one',
            ]);
        }
        setLoading(false);
    }
    return (
        <>
            <PrimaryButton icon={<ArrowLeftIcon className="w-4 h-4" />} label={"Go Back"} onClickEvent={() => navigate(`/admin/hardware`)} classNames='text-gray-400 w-fit m-8 border bg-transparent hover:bg-gray-100' />
            <div className='mt-10 lg:w-1/2 mx-auto'  >
                <h1 className="text-2xl font-bold">Edit Hardware</h1>
                <Input
                    options={{
                        label: 'HWID',
                        name: "hwid",
                        id: "hwid",
                        value: hwid,
                        onChange: (e) => setHwid(e.target.value),
                        type: 'text'
                    }}
                />
                <div className="mt-5 sm:mt-6">
                    <button
                        type="button"
                        disabled={false}
                        className="inline-flex justify-center max-h-10 w-full rounded-md border border-transparent px-4 py-2 bg-blue-500 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                        onClick={handleSubmit}
                    >
                        {loading ? <Loader height="h-full" width="w-auto" /> : "Submit"}
                    </button>
                </div>
            </div>

        </>
    )
}