import React, { useEffect, useState } from "react";
import { useApiOperation } from "../../hooks/useApiOperation";
import { ChemicalsApi } from "../../lib/chemicals.api";
import { JobsApi } from "../../lib/jobs.api";
import { NOTIFY_TYPE } from "../../utils/constants";
import { CHEMICAL_OPTIONS } from "../../utils/helpers";
import { PrimaryButton } from "../Button/PrimaryButton";


const ChemicalDropdown = ({ selectedChemical, setSelectedChemical }) => {
  const [chemicals, setChemicals] = useState([]);
  const { startApiOperation,terminateApiOperation} =useApiOperation()
  const fetchChemicals = async () => {
    try {
      startApiOperation()
      const response = await ChemicalsApi.fetchChemicals();
      setChemicals(response);
      terminateApiOperation()
    } catch (error) {
      console.error('Failed to fetch chemicals', error);
      terminateApiOperation(['Failed to fetch chemicals'])
    }
  };
  useEffect(() => {

    fetchChemicals();
  }, []);

  useEffect(() => {
    if (selectedChemical) setSelectedChemical(selectedChemical);

  }, [chemicals]);

  const handleChemicalChange = (e) => {
    const selectedId = e.target.value;
    const selected = chemicals.find((chem) => chem._id === selectedId);
    setSelectedChemical(selected || null);
  };
  const RenderChemicalOptions = ({chemicals}) => {
    if (!chemicals || chemicals.length === 0) {
      return <option disabled>No chemicals available</option>;
    }
  
    return chemicals.map((chemical, index) => (
      <option key={`${chemical._id}-${index}`} value={chemical._id}>
        {chemical.chemicalName} - {chemical.concentration} {chemical.measurement}
      </option>
    ));
  };
  return (
    <div>
      <select
        value={selectedChemical ? selectedChemical._id : ''}
        onChange={handleChemicalChange}
        className="border border-gray-300 focus-visible:outline-none focus:border-blue-600 hover:border-blue-400 w-full p-2 rounded-lg mb-2"
      >
        <option value="">-- Select a Chemical --</option>
        <RenderChemicalOptions chemicals={chemicals} />
        
      </select>


    </div>
  );
};




const DisplayAddNewChemical = ({ apiLoading, active, chemicalQuantity, setChemicalQuantity, handleSaveChemical, type, setType, selectedChemical, setSelectedChemical }) => {
  if (!active) return null;

  return (
    <div className="w-full">
      <label className="flex justify-start items-center text-[14px] mb-6">
        <RenderSubTitle type={type} />
      </label>
      <form onSubmit={handleSaveChemical}>
        <ChemicalDropdown selectedChemical={selectedChemical} setSelectedChemical={setSelectedChemical} />

        <input
          type="number"
          required
          placeholder="Quantity"
          className="border border-gray-300 focus-visible:outline-none focus:border-blue-600 hover:border-blue-400 w-full p-2 rounded-lg"
          value={chemicalQuantity}
          onChange={(e) => setChemicalQuantity(e.target.value)}
        />
        <div className="grid grid-cols-1 md:grid-cols-2  gap-5 mt-4">
          <PrimaryButton label={'Cancel'} classNames="mt-2 bg-transparent border text-dark hover:bg-gray-100" onClickEvent={() => setType('')} />
          <PrimaryButton btnType="submit" loading={apiLoading} label={'Save Chemical'} classNames="mt-2 text-white" />
        </div>
      </form>

    </div>
  );
};



const RenderSubTitle = ({ type }) => {
  if (type == CHEMICAL_OPTIONS.ADD) return <span>Add New Chemical</span>
  return <span>Edit Chemical</span>
}
export const AddChemicals = ({  setShouldRefetch, preSelectedChemical, jobId, selectedChemical, type, setType, setSelectedChemical }) => {
  const { startApiOperation, terminateApiOperation, apiLoading } = useApiOperation()
  const [chemicalQuantity, setChemicalQuantity] = useState('');



  const clearStates = () => {

    setChemicalQuantity('');

    setShouldRefetch(true);
    setType('');
  }
  const handleSaveChemical = async (e) => {
    e.preventDefault()
    try {
      startApiOperation()
      if (type == CHEMICAL_OPTIONS.EDIT) {
        const payload = {
          id: jobId,
          preChemicalID: preSelectedChemical._id,
          chemical: selectedChemical,
          totalSprayUsed: chemicalQuantity
        }
        const { message } = await JobsApi.updateChemical(payload);
        terminateApiOperation([`${message}`], NOTIFY_TYPE.Success)
      }
      else {
        const payload = {
          id: jobId,
          chemical: selectedChemical,
          totalSprayUsed: chemicalQuantity
        }
        const { message } = await JobsApi.addChemical(payload);
        terminateApiOperation([`${message}`], NOTIFY_TYPE.Success)

      }
      clearStates()

    } catch (error) {
      terminateApiOperation([error])

    }

  };

  if (!type) return null
  return (
    <div className="mt-6 space-y-4 gap-5 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
      <div className="flex overflow-hidden rounded-lg bg-white shadow-sm border-gray-300 border p-6">
        <div className="p-0 sm:p-3 w-full">
          <h3 className="text-xl font-medium">Chemicals</h3>

          <DisplayAddNewChemical
            selectedChemical={selectedChemical}
            setSelectedChemical={setSelectedChemical}
            setType={setType}
            apiLoading={apiLoading}
            type={type}
            active={true}
            handleSaveChemical={handleSaveChemical}
            chemicalQuantity={chemicalQuantity}
            setChemicalQuantity={setChemicalQuantity}
          />
        </div>
      </div>
    </div>
  );
};