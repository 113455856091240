import React from 'react'

const Skeleton = () => {
  return (
    <div className="border rounded-lg shadow-md bg-gradient-to-r from-gray-50 to-gray-100 animate-pulse p-4 h-36 flex flex-col justify-between">
    <div className="h-4 bg-gray-200 rounded w-2/3"></div>
    <div className="h-4 bg-gray-200 rounded w-1/2"></div>
    <div className="h-4 bg-gray-200 rounded w-full"></div>
  </div>
  )
}

export default Skeleton
