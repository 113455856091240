import { useEffect, useRef } from 'react';
import { STATE_CODES } from '../../utils/constants';
import { SingleSelect } from '../shared/dropdowns/SingleSelect';
import CustomInput from '../shared/inputs/CustomInput';



const StripeAddressInfo = ({ addressInfo, handleChange }) => {
  const stateCodes=STATE_CODES
  const STATE_CODES_ARRAY= Object.entries(stateCodes).map(([code, name]) => ({
    value: code,
    label: name,
  }));
  const { city, zip, state, streetLine1, streetLine2 } = addressInfo;

  
  const hasFetchedTax = useRef(false);

  useEffect(() => {
    const isAddressValid =
      addressInfo &&
      addressInfo.city &&
      addressInfo.zip &&
      addressInfo.state &&
      addressInfo.streetLine1;
    // If address is valid and fetch tax only once.
    if (!hasFetchedTax.current && isAddressValid) {
      hasFetchedTax.current = true;
    }
  }, [addressInfo]);

  

  return (
    <div className="w-full space-y-5 px-4 md:grid md:grid-cols-2 md:gap-5 md:space-y-0 md:px-6">
      {/* address line  */}
      <div className="col-span-1">
        <CustomInput
          options={{
            heading_label: 'Address line 1',
            name: 'address',
            id: 'address',
            value: streetLine1,
            onChange: (e) => {
              handleChange('streetLine1', e.target.value);
            },

            autoComplete: 'address',
            placeholder: 'Enter Address',
            type: 'text',
          }}
        />
      </div>
      {/* Apartment  */}
      <div className="col-span-1">
        <CustomInput
          options={{
            heading_label: 'Apartment',
            name: 'line2',
            id: 'line2',
            value: streetLine2,
            onChange: (e) => {
              handleChange('streetLine2', e.target.value);
            },
            autoComplete: 'line2',
            placeholder: 'Enter Apartment Number',
            type: 'text',
          }}
        />
      </div>
      {/* postal city state  */}
      <div className="col-span-2">
        <div className="grid grid-cols-3 gap-5">
          {/* postal */}
          <div className="col-span-3 lg:col-span-1">
            <CustomInput
              options={{
                heading_label: 'Postal code',
                name: 'postal-code',
                id: 'postal-code',
                value: zip,
        
                onChange: (e) => handleChange('zip', e.target.value),
                autoComplete: 'postal-code',
                placeholder: 'Enter Postal Code',
                type: 'text',
              }}
            />
          </div>
          {/* city */}
          <div className="col-span-3 lg:col-span-1">
            <CustomInput
              options={{
                heading_label: 'City',
                name: 'city',
                id: 'city',
                value: city,
                onChange: (e) => {
                  handleChange('city', e.target.value);
                },
                autoComplete: 'city',
                placeholder: 'Enter city',
                type: 'text',
              }}
            />
          </div>
          {/* state */}
          <div className="col-span-3 lg:col-span-1">
            <div className="  w-full">
              <label className="mb-1 flex items-center justify-start text-gray-600 text-sm font-bold">
                <span>State</span>
              </label>
            </div>

            <SingleSelect
              key={state || 'empty'}
              options={STATE_CODES_ARRAY}
              displayValue="label"
              currentValue={STATE_CODES_ARRAY.find(({ value }) => value === state)}
              onChange={(selectedOption) => handleChange('state', selectedOption?.value || '')}
              placeholder="Select a Region"
              search
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { StripeAddressInfo };
