import React from 'react';

const PageTitleHeader = ({ title, titleStyles, subHeading }) => {

  const RenderSubHeading =()=>{
    if(subHeading) return <p className="text-sm text-gray-600 mt-4">{subHeading}</p>
  }
  return (
    <div className="w-full ">
      <h1 className={`font-bold text-gray-900 ${titleStyles}`}>{title}</h1>
      <RenderSubHeading />
    </div>
  );
};

export default PageTitleHeader;