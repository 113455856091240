import { ArrowUturnLeftIcon, ChevronLeftIcon, XCircleIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from '../../../components/Button/PrimaryButton';
import { setStep3ExcludedCols } from "../../../features/csvDataSlice";
import { useApiOperation } from "../../../hooks/useApiOperation";
import StepDropdown from "./StepDropdown";
import Stepper from './Stepper';


const Step3 = () => {
  const dispatch = useDispatch();
  const selectedData = useSelector((state) => state.csvData.step2SelectedData);
  const [excludedCols, setExcludedCols] = useState([]);
  const currentStep = 3;
  const { terminateApiOperation } = useApiOperation();
  const navigate = useNavigate();

  const [errorMessages, setErrorMessages] = useState([]);
  const flowStep = 1

  const requiredHeaders = [
    {
      name: 'clientName',
      key: 'clientName',
      isOptional: false,
      isSelected: -1,
    },
    {
      name: 'email',
      key: 'email',
      isOptional: true,
      isSelected: -1
    },
    {
      name: 'address',
      key:  'address',
      isOptional: false,
      isSelected: -1
    },
    {
      name: 'Square Feet',
      key: 'Square_Feet',
      isOptional: true,
      isSelected: -1
    },
  ];
  const [selectedDropdown, setSelectedDropdown] = useState(requiredHeaders);

  useEffect (()=>{
    if (!selectedData.length) {
      navigate("/clients/select-header");
    }
  },[])

  if (!selectedData || selectedData.length === 0) {
    return <p>No data received. Please go back and select a row.</p>;
  }

  const handleExclude = (index) => {
    if (excludedCols.includes(index)) {
     
      setExcludedCols(excludedCols.filter((col) => col !== index));
    } else {
      
      setExcludedCols([...excludedCols, index]);
  
      
      setSelectedDropdown((prevDropdown) =>
        prevDropdown.map((item, idx) =>
          idx === index ? { ...item, isSelected: -1 } : item
        )
      );
    }
  };

  const headers = selectedData.length > 0 ? Object.keys(selectedData[0]) : [];
  
  const handleNext = () => {
    if (selectedDropdown.filter((item) => item.isSelected !== -1).length < 3) {
      terminateApiOperation(["Please map at least three properties."]);
      return;
    }
  
    const updatedHeaders = headers.map((header, idx) => {
      const mappedHeader = selectedDropdown.find((item) => item.isSelected === idx);
      return {
        original: header,
        mapped: mappedHeader ? mappedHeader.name : header,
        index: idx
      };
    }).filter(({ index }) => !excludedCols.includes(index));
    dispatch(setStep3ExcludedCols(updatedHeaders));
    navigate("/clients/validate");
  };
  
  const handleBack = () => {
    navigate("/clients/select-header")
  }
  const Headers = () => {
    if (!headers.length) return null;
    
    return (
      <>
        {headers.map((header, idx) => {
          // Find the selected header mapping
          const mappedHeader = selectedDropdown.find((item) => item.isSelected === idx);
          const displayHeader = mappedHeader ? mappedHeader.name : header;
  
          return (
            <th key={idx} className="px-4 py-2 text-left font-semibold text-gray-700">
              <div className="flex justify-between items-center">
                {displayHeader}
                <button
                  className="bg-gray-300 h-8 w-8 rounded-md flex justify-center items-center"
                  onClick={() => handleExclude(idx)}
                >
                  {excludedCols.includes(idx) ? (
                    <ArrowUturnLeftIcon className="h-6 w-6 text-gray-500" />
                  ) : (
                    <XCircleIcon className="h-6 w-6 text-gray-500" />
                  )}
                </button>
              </div>
            </th>
          );
        })}
      </>
    );
  }

  const TableBody = () =>{
    if (!selectedData.length) return null;
    return (
      <>
        {selectedData.map((row, rowIndex) => (
          <tr
            key={rowIndex}
            className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
          >
            {headers.map((header, colIndex) => (
              <td
                key={colIndex}
                className={`px-4 py-2 text-gray-700 border-t border-gray-300 ${excludedCols.includes(colIndex) ? 'opacity-50' : ''
                }`}
              >
                {row[header]}
              </td>
            ))}
          </tr>
        ))}
      </>
    );
  }

  const DropdownHeaders = () => {
    return (
      <>
        {headers.map((ind, idx) => (
          <div key={idx} className="col-span-1 w-full">
            {!excludedCols.includes(idx) ? (
              <StepDropdown flowStep={flowStep} index={idx} selectedDropdown={selectedDropdown} setErrorMessages={setErrorMessages} setSelectedDropdown={setSelectedDropdown}/>
            ) : (
              <div className="flex justify-center items-center text-xl font-medium">
                Column Ignored
              </div>
            )}
          </div>
        ))}
      </>
    );
  }

  return (
      <div className="p-5">
        <div className="flex justify-between">
          <PrimaryButton
            onClickEvent={handleBack}
            label={'Back'}
            icon={<ChevronLeftIcon className="w-4 h-4" />}
            classNames="gap-x-1 items-start text-white"
          />
          <PrimaryButton
            onClickEvent={handleNext}
            label={'Next'}
            classNames="gap-x-1 items-start text-white"
          />
        </div>
        <Stepper currentStep={currentStep} />

        {/* Section 1: Display the Table */}
        <div className="mt-6">
          <h2 className="text-2xl font-extrabold mb-6">Select Header Row</h2>
          <p className="text-lg font-semibold">Your Table</p>
          <div className="border rounded-t-lg mb-6 mt-4 overflow-auto overflow-x-auto" style={{ maxHeight: "550px" }}>
            <table className="w-full divide-y divide-gray-300 table-fixed">
              <thead className="bg-gray-50">
                <tr>
                  <Headers/>
                </tr>
              </thead>
              <tbody>
                <TableBody/>
              </tbody>
            </table>
          </div>
        </div>

        {/* Section 2: Editable Dropdowns */}
        <p className="text-lg font-semibold">Will Become</p>
        <div className="rounded-t-2xl mb-12 py-5">
        <div
            className={`grid grid-cols-${headers.length} gap-4`}
          >
            <DropdownHeaders/>
          </div>
        </div>
      </div>
  );
};

export default Step3;
