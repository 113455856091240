import { BoltIcon, MagnifyingGlassIcon, MapPinIcon, UserGroupIcon } from "@heroicons/react/24/outline";

export const PAGE_LIMIT = 9

export const FILTER_LIST = [
  { name: 'Today' },
  { name: 'Yesterday' },
  { name: 'This Week' },
  { name: 'Previous Week' },
  { name: 'Last 7 Days' },
  { name: 'Last 15 Days' },
  { name: 'This Month' },
  { name: 'Previous Month' },
  { name: 'Last 30 Days' },
  { name: 'Last 60 Days' },
  { name: 'Last Year' },
];


export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
}

export const NOTIFY_TYPE = {
  Success: 'success',
  Error: 'error',
  Info: 'info',
  Warning: 'warning',
}


export const ButtonSizes = {
  EXTRASMALL: 'EXTRASMALL',
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
  EXTRALARGE: 'EXTRALARGE',
  FULL: 'FULL',
  FIT: 'FIT',
}


export const ButtonVariants = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  DANGER: 'DANGER',
  DISABLED: 'DISABLED',
  TABS: 'TABS',
  LINK: 'LINK',
  CLIPBOARD: 'CLIPBOARD',
  DETAILS: 'DETAILS',
}


export const ButtonTypeVariant = {
  BUTTON: 'button',
  SUBMIT: 'submit',
  Reset: 'reset',
}

export const CardCheckType = {
  radio: 'radio',
  checkbox: 'checkbox',
}

export const CheckboxVariants = {
  PRIMARY: 'PRIMARY',
  SINGLE: 'SINGLE',
}

export const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS"


export const userActions = [
  {
    icon: UserGroupIcon ,
    name: "Clients",
    href: "/clients",
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
    description: "This is the clients data",
  },
  {
    icon: MapPinIcon,
    name: "Map",
    href: "/mapview/properties",
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
    description: "This is the map",
  },
  {
    icon: BoltIcon,
    name: "Start Job",
    href: "/start",
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
    description: "Click here to start a job",
  },

];

export const technicianActions = [
  {
    icon: MapPinIcon,
    name: "Map",
    href: "/mapview/properties",
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
    description: "This is the map",
  },
  {
    icon: BoltIcon,
    name: "Start Job",
    href: "/start",
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
    description: "Click here to start a job",
  },
  {
    icon: MagnifyingGlassIcon,
    name: "Jobs",
    href: "/jobs",
    iconForeground: "text-red-700",
    iconBackground: "bg-red-50",
    description: "Click here to search all jobs",
  },
];
export const STATE_CODES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
  NL: 'Newfoundland and Labrador',
  PE: 'Prince Edward Island',
  NS: 'Nova Scotia',
  NB: 'New Brunswick',
  QC: 'Quebec',
  ON: 'Ontario',
  MB: 'Manitoba',
  SK: 'Saskatchewan',
  AB: 'Alberta',
  BC: 'British Columbia',
  YT: 'Yukon',
  NT: 'Northwest Territories',
  NU: 'Nunavut',
};