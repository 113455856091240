import { HTTP_METHODS } from "../utils/constants";
import { sendRequest } from "../utils/request-service";

export const API_REQUESTS = {
  RETRIEVE_OR_CREATE_CUSTOMER: {
    path: "/payments/customer",
    method: HTTP_METHODS.POST,
  },
  CHARGE_CUSTOMER: {
    path: "/payments/chargeCustomer",
    method: HTTP_METHODS.POST,
  },
  GET_CUSTOMER_CARD: {
    path: "/payments/getCustomerCardInfo",
    method: HTTP_METHODS.GET,
  },
  GET_BILLING_HISTORY: {
    path: "/payments/getBillingHistory",
    method: HTTP_METHODS.GET,
  },
  UPDATE_PAYMENT_CARD: {
    path: "/payments/updatePaymentCard",
    method: HTTP_METHODS.POST,
  },
  CREATE_CHECKOUT_SESSION: {
    path: "/payments/createCheckoutSession",
    method: HTTP_METHODS.POST,
  },

  CREATE_STRIPE_CUSTOMER: {
    path: "/payments/createStripeCustomer",
    method: HTTP_METHODS.POST,
  },
  ATTACH_PAYMENT_METHOD: {
    path: "/payments/attachPaymentMethod",
    method: HTTP_METHODS.POST,
  },
  PAY_INVOICE: {
    path: "/payments/payInvoice",
    method: HTTP_METHODS.POST,
  },
  PAY_SUBSCRIPTION: {
    path: "/payments/paySubscription",
    method: HTTP_METHODS.POST,
  },
};

export const StripeApi = {
  getOrCreateCustomer: (data) => {
    return sendRequest(
      API_REQUESTS.RETRIEVE_OR_CREATE_CUSTOMER.method,
      `${API_REQUESTS.RETRIEVE_OR_CREATE_CUSTOMER.path}`,
      data
    );
  },
  createPaymentIntent: (data) => {
    return sendRequest(
      API_REQUESTS.CHARGE_CUSTOMER.method,
      `${API_REQUESTS.CHARGE_CUSTOMER.path}`,
      data
    );
  },
  getCustomerCardInfo: () => {
    return sendRequest(
      API_REQUESTS.GET_CUSTOMER_CARD.method,
      `${API_REQUESTS.GET_CUSTOMER_CARD.path}`
    );
  },

  getBillingHistory: (queryParams = "") => {
    return sendRequest(
      API_REQUESTS.GET_BILLING_HISTORY.method,
      `${API_REQUESTS.GET_BILLING_HISTORY.path}`,
      null,
      null,
      queryParams
    );
  },
  updateCustomerCard: (data) => {
    return sendRequest(
      API_REQUESTS.UPDATE_PAYMENT_CARD.method,
      `${API_REQUESTS.UPDATE_PAYMENT_CARD.path}`,
      data
    );
  },
  createCheckoutSession: (data) => {
    return sendRequest(
      API_REQUESTS.CREATE_CHECKOUT_SESSION.method,
      `${API_REQUESTS.CREATE_CHECKOUT_SESSION.path}`,
      data
    );
  },

  createStripeCustomer: (data) => {
    return sendRequest(
      API_REQUESTS.CREATE_STRIPE_CUSTOMER.method,
      `${API_REQUESTS.CREATE_STRIPE_CUSTOMER.path}`,
      data
    );
  },

  attachPaymentMethod: (data) => {
    return sendRequest(
      API_REQUESTS.ATTACH_PAYMENT_METHOD.method,
      `${API_REQUESTS.ATTACH_PAYMENT_METHOD.path}`,
      data
    );
  },

  payInvoice: (data) => {
    return sendRequest(
      API_REQUESTS.PAY_INVOICE.method,
      `${API_REQUESTS.PAY_INVOICE.path}`,
      data
    );
  },

  paySubscription: (data) => {
    return sendRequest(
      API_REQUESTS.PAY_SUBSCRIPTION.method,
      `${API_REQUESTS.PAY_SUBSCRIPTION.path}`,
      data
    );
  },
};
